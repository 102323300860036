export const LOGIN_SUCCEEDED = "login_success";
export const LOGIN_FAILED = "login_fail";
export const LOGOUT = "logout";
export const CLIENT_LOADED = "client_loaded";
export const CASES_RECEIVED = "cases_received";
export const ALERTS_RECEIVED = "alerts_received";
export const STATS_RECEIVED = "stats_received";
export const DOCS_RECEIVED = "docs";
export const STATEMENTS_RECEIVED = "statements_received";
export const ADD_DOCS = "add_docs";
export const OPEN_UPLOAD_DIALOG = "open_upload_dialog";
export const OPEN_ALERT = "open_alert";
export const CLOSE_MODALS = "close_modals";
