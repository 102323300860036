export const MIME_TYPES = [
    {
        "label": "Lotus 2-2-3",
        "type": "application/vnd.lotus-1-2-3",
        "extension": ".123"
    },
    {
        "label": "3GP2",
        "type": "video/3gpp2",
        "extension": ".3g2"
    },
    {
        "label": "3GP",
        "type": "video/3gpp",
        "extension": ".3gp"
    },
    {
        "label": "7-Zip",
        "type": "application/x-7z-compressed",
        "extension": ".7z"
    },
    {
        "label": "Adobe (Macropedia) Authorware – Binary File",
        "type": "application/x-authorware-bin",
        "extension": ".aab"
    },
    {
        "label": "Advanced Audio Coding (AAC)",
        "type": "audio/x-aac",
        "extension": ".aac"
    },
    {
        "label": "Adobe (Macropedia) Authorware – Map",
        "type": "application/x-authorware-map",
        "extension": ".aam"
    },
    {
        "label": "Adobe (Macropedia) Authorware – Segment File",
        "type": "application/x-authorware-seg",
        "extension": ".aas"
    },
    {
        "label": "AbiWord",
        "type": "application/x-abiword",
        "extension": ".abw"
    },
    {
        "label": "Active Content Compression",
        "type": "application/vnd.americandynamics.acc",
        "extension": ".acc"
    },
    {
        "label": "Ace Archive",
        "type": "application/x-ace-compressed",
        "extension": ".ace"
    },
    {
        "label": "ACU Cobol",
        "type": "application/vnd.acucobol",
        "extension": ".acu"
    },
    {
        "label": "Adaptive differential pulse-code modulation",
        "type": "audio/adpcm",
        "extension": ".adp"
    },
    {
        "label": "MO:DCA-P",
        "type": "application/vnd.ibm.modcap",
        "extension": ".afp"
    },
    {
        "label": "Ahead AIR Application",
        "type": "application/vnd.ahead.space",
        "extension": ".ahead"
    },
    {
        "label": "Adobe AIR Application",
        "type": "application/vnd.adobe.air-application-installer-package+zip",
        "extension": ".air"
    },
    {
        "label": "Digital Video Broadcasting",
        "type": "application/vnd.dvb.ait",
        "extension": ".ait"
    },
    {
        "label": "AmigaDE",
        "type": "application/vnd.amiga.ami",
        "extension": ".ami"
    },
    {
        "label": "Android Package Archive",
        "type": "application/vnd.android.package-archive",
        "extension": ".apk"
    },
    {
        "label": "ACU Cobol",
        "type": "application/vnd.acucorp",
        "extension": ".atc"
    },
    {
        "label": "Antix Game Player",
        "type": "application/vnd.antix.game-component",
        "extension": ".atx"
    },
    {
        "label": "Applixware",
        "type": "application/applixware",
        "extension": ".aw"
    },
    {
        "label": "AirZip FileSECURE",
        "type": "application/vnd.airzip.filesecure.azf",
        "extension": ".azf"
    },
    {
        "label": "AirZip FileSECURE",
        "type": "application/vnd.airzip.filesecure.azs",
        "extension": ".azs"
    },
    {
        "label": "Amazon Kindle eBook format",
        "type": "application/vnd.amazon.ebook",
        "extension": ".azw"
    },
    {
        "label": "ANSER-WEB Terminal Client – Certificate Issue",
        "type": "application/vnd.anser-web-certificate-issue-initiation",
        "extension": ".cii"
    },
    {
        "label": "Adobe Shockwave Player",
        "type": "application/x-director",
        "extension": ".dir"
    },
    {
        "label": "ANSER-WEB Terminal Client – Web Funds Transfer",
        "type": "application/vnd.anser-web-funds-transfer-initiation",
        "extension": ".fti"
    },
    {
        "label": "Adobe Flex Project",
        "type": "application/vnd.adobe.fxp",
        "extension": ".fxp"
    },
    {
        "label": "Archipelago Lesson Player",
        "type": "application/vnd.hhe.lesson-player",
        "extension": ".les"
    },
    {
        "label": "Apple Installer Package",
        "type": "application/vnd.apple.installer+xml",
        "extension": ".mpkg"
    },
    {
        "label": "3GPP MSEQ File",
        "type": "application/vnd.mseq",
        "extension": ".mseq"
    },
    {
        "label": "Portable Document Format",
        "type": "application/pdf",
        "extension": ".pdf"
    },
    {
        "label": "3rd Generation Partnership Project – Pic Large",
        "type": "application/vnd.3gpp.pic-bw-large",
        "extension": ".plb"
    },
    {
        "label": "Adobe PostScript Printer Description File Format",
        "type": "application/vnd.cups-ppd",
        "extension": ".ppd"
    },
    {
        "label": "3rd Generation Partnership Project – Pic Small",
        "type": "application/vnd.3gpp.pic-bw-small",
        "extension": ".psb"
    },
    {
        "label": "3rd Generation Partnership Project – Pic Var",
        "type": "application/vnd.3gpp.pic-bw-var",
        "extension": ".pvb"
    },
    {
        "label": "3M Post It Notes",
        "type": "application/vnd.3m.post-it-notes",
        "extension": ".pwn"
    },
    {
        "label": "Assembler Source File",
        "type": "text/x-asm",
        "extension": ".s"
    },
    {
        "label": "Adobe Flash",
        "type": "application/x-shockwave-flash",
        "extension": ".swf"
    },
    {
        "label": "Arista Networks Software Image",
        "type": "application/vnd.aristanetworks.swi",
        "extension": ".swi"
    },
    {
        "label": "3rd Generation Partnership Project – Transaction Capabilities Application Part",
        "type": "application/vnd.3gpp2.tcap",
        "extension": ".tcap"
    },
    {
        "label": "3D Crossword Plugin",
        "type": "application/vnd.hzn-3d-crossword",
        "extension": ".x3d"
    },
    {
        "label": "Adobe XML Data Package",
        "type": "application/vnd.adobe.xdp+xml",
        "extension": ".xdp"
    },
    {
        "label": "Adobe XML Forms Data Format",
        "type": "application/vnd.adobe.xfdf",
        "extension": ".xfdf"
    },
    {
        "label": "Andrew Toolkit",
        "type": "N/A"
    },
    {
        "label": "In3D – 3DML",
        "type": "text/vnd.in3d.3dml",
        "extension": ".3dml"
    },
    {
        "label": "Attribute Certificate",
        "type": "application/pkix-attr-cert",
        "extension": ".ac"
    },
    {
        "label": "Audiograph",
        "type": "application/vnd.audiograph",
        "extension": ".aep"
    },
    {
        "label": "PostScript",
        "type": "application/postscript",
        "extension": ".ai"
    },
    {
        "label": "Audio Interchange File Format",
        "type": "audio/x-aiff",
        "extension": ".aif"
    },
    {
        "label": "Microsoft ClickOnce",
        "type": "application/x-ms-application",
        "extension": ".application"
    },
    {
        "label": "Lotus Approach",
        "type": "application/vnd.lotus-approach",
        "extension": ".apr"
    },
    {
        "label": "Microsoft Advanced Systems Format (ASF)",
        "type": "video/x-ms-asf",
        "extension": ".asf"
    },
    {
        "label": "Preview Systems ZipLock/VBox",
        "type": "application/vnd.previewsystems.box",
        "extension": ".box"
    },
    {
        "label": "Microsoft Trust UI Provider – Security Catalog",
        "type": "application/vnd.ms-pki.seccat",
        "extension": ".cat"
    },
    {
        "label": "pIRCh",
        "type": "application/x-chat",
        "extension": ".chat"
    },
    {
        "label": "Mobius Management Systems – UniversalArchive",
        "type": "application/vnd.mobius.daf",
        "extension": ".daf"
    },
    {
        "label": "OMA Download Agents",
        "type": "application/vnd.oma.dd2+xml",
        "extension": ".dd2"
    },
    {
        "label": "Mobius Management Systems – Distribution Database",
        "type": "application/vnd.mobius.dis",
        "extension": ".dis"
    },
    {
        "label": "New Moon Liftoff/DNA",
        "type": "application/vnd.dna",
        "extension": ".dna"
    },
    {
        "label": "Microsoft Word",
        "type": "application/msword",
        "extension": ".doc"
    },
    {
        "label": "Microsoft Office – OOXML – Word Document",
        "type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "extension": ".docx"
    },
    {
        "label": "Microsoft Office – OOXML – Word Document Template",
        "type": "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
        "extension": ".dotx"
    },
    {
        "label": "OSGi Deployment Package",
        "type": "application/vnd.osgi.dp",
        "extension": ".dp"
    },
    {
        "label": "PRS Lines Tag",
        "type": "text/prs.lines.tag",
        "extension": ".dsc"
    },
    {
        "label": "Nuera ECELP 4800",
        "type": "audio/vnd.nuera.ecelp4800",
        "extension": ".ecelp4800"
    },
    {
        "label": "Nuera ECELP 7470",
        "type": "audio/vnd.nuera.ecelp7470",
        "extension": ".ecelp7470"
    },
    {
        "label": "Nuera ECELP 9600",
        "type": "audio/vnd.nuera.ecelp9600",
        "extension": ".ecelp9600"
    },
    {
        "label": "Novadigm’s RADIA and EDM products",
        "type": "application/vnd.novadigm.edm",
        "extension": ".edm"
    },
    {
        "label": "Novadigm’s RADIA and EDM products",
        "type": "application/vnd.novadigm.edx",
        "extension": ".edx"
    },
    {
        "label": "Pcsel eFIF File",
        "type": "application/vnd.picsel",
        "extension": ".efif"
    },
    {
        "label": "Proprietary P&amp;G Standard Reporting System",
        "type": "application/vnd.pg.osasli",
        "extension": ".ei6"
    },
    {
        "label": "Novadigm’s RADIA and EDM products",
        "type": "application/vnd.novadigm.ext",
        "extension": ".ext"
    },
    {
        "label": "mod_fly / fly.cgi",
        "type": "text/vnd.fly",
        "extension": ".fly"
    },
    {
        "label": "NpGraphIt",
        "type": "application/vnd.flographit",
        "extension": ".gph"
    },
    {
        "label": "OpenGL Textures (KTX)",
        "type": "image/ktx",
        "extension": ".ktx"
    },
    {
        "label": "MPEG-21",
        "type": "application/mp21",
        "extension": ".m21"
    },
    {
        "label": "Multimedia Playlist Unicode",
        "type": "application/vnd.apple.mpegurl",
        "extension": ".m3u8"
    },
    {
        "label": "Mobius Management Systems – Basket file",
        "type": "application/vnd.mobius.mbk",
        "extension": ".mbk"
    },
    {
        "label": "MIDI – Musical Instrument Digital Interface",
        "type": "audio/midi",
        "extension": ".mid"
    },
    {
        "label": "Motion JPEG 2000",
        "type": "video/mj2",
        "extension": ".mj2"
    },
    {
        "label": "MPEG-4 Video",
        "type": "video/mp4",
        "extension": ".mp4"
    },
    {
        "label": "MPEG4",
        "type": "application/mp4",
        "extension": ".mp4"
    },
    {
        "label": "MPEG-4 Audio",
        "type": "audio/mp4",
        "extension": ".mp4a"
    },
    {
        "label": "Mophun Certificate",
        "type": "application/vnd.mophun.certificate",
        "extension": ".mpc"
    },
    {
        "label": "MPEG Video",
        "type": "video/mpeg",
        "extension": ".mpeg"
    },
    {
        "label": "MPEG Audio",
        "type": "audio/mpeg",
        "extension": ".mpga"
    },
    {
        "label": "Mophun VM",
        "type": "application/vnd.mophun.application",
        "extension": ".mpn"
    },
    {
        "label": "Microsoft Project",
        "type": "application/vnd.ms-project",
        "extension": ".mpp"
    },
    {
        "label": "MiniPay",
        "type": "application/vnd.ibm.minipay",
        "extension": ".mpy"
    },
    {
        "label": "Mobius Management Systems – Query File",
        "type": "application/vnd.mobius.mqy",
        "extension": ".mqy"
    },
    {
        "label": "Mobius Management Systems – Script Language",
        "type": "application/vnd.mobius.msl",
        "extension": ".msl"
    },
    {
        "label": "Muvee Automatic Video Editing",
        "type": "application/vnd.muvee.style",
        "extension": ".msty"
    },
    {
        "label": "MUsical Score Interpreted Code Invented for the ASCII designation of Notation",
        "type": "application/vnd.musician",
        "extension": ".mus"
    },
    {
        "label": "MXML",
        "type": "application/xv+xml",
        "extension": ".mxml"
    },
    {
        "label": "MPEG Url",
        "type": "video/vnd.mpegurl",
        "extension": ".mxu"
    },
    {
        "label": "N-Gage Game Installer",
        "type": "application/vnd.nokia.n-gage.symbian.install",
        "extension": ".n-gage"
    },
    {
        "label": "Notation3",
        "type": "text/n3",
        "extension": ".n3"
    },
    {
        "label": "Network Common Data Form (NetCDF)",
        "type": "application/x-netcdf",
        "extension": ".nc"
    },
    {
        "label": "Navigation Control file for XML (for ePub)",
        "type": "application/x-dtbncx+xml",
        "extension": ".ncx"
    },
    {
        "label": "N-Gage Game Data",
        "type": "application/vnd.nokia.n-gage.data",
        "extension": ".ngdat"
    },
    {
        "label": "neuroLanguage",
        "type": "application/vnd.neurolanguage.nlu",
        "extension": ".nlu"
    },
    {
        "label": "NobleNet Directory",
        "type": "application/vnd.noblenet-directory",
        "extension": ".nnd"
    },
    {
        "label": "NobleNet Sealer",
        "type": "application/vnd.noblenet-sealer",
        "extension": ".nns"
    },
    {
        "label": "NobleNet Web",
        "type": "application/vnd.noblenet-web",
        "extension": ".nnw"
    },
    {
        "label": "Microsoft Office Binder",
        "type": "application/x-msbinder",
        "extension": ".obd"
    },
    {
        "label": "Office Document Architecture",
        "type": "application/oda",
        "extension": ".oda"
    },
    {
        "label": "OpenDocument Database",
        "type": "application/vnd.oasis.opendocument.database",
        "extension": ".odb"
    },
    {
        "label": "OpenDocument Chart",
        "type": "application/vnd.oasis.opendocument.chart",
        "extension": ".odc"
    },
    {
        "label": "OpenDocument Formula",
        "type": "application/vnd.oasis.opendocument.formula",
        "extension": ".odf"
    },
    {
        "label": "OpenDocument Formula Template",
        "type": "application/vnd.oasis.opendocument.formula-template",
        "extension": ".odft"
    },
    {
        "label": "OpenDocument Graphics",
        "type": "application/vnd.oasis.opendocument.graphics",
        "extension": ".odg"
    },
    {
        "label": "OpenDocument Image",
        "type": "application/vnd.oasis.opendocument.image",
        "extension": ".odi"
    },
    {
        "label": "OpenDocument Text Master",
        "type": "application/vnd.oasis.opendocument.text-master",
        "extension": ".odm"
    },
    {
        "label": "OpenDocument Presentation",
        "type": "application/vnd.oasis.opendocument.presentation",
        "extension": ".odp"
    },
    {
        "label": "OpenDocument Spreadsheet",
        "type": "application/vnd.oasis.opendocument.spreadsheet",
        "extension": ".ods"
    },
    {
        "label": "OpenDocument Text",
        "type": "application/vnd.oasis.opendocument.text",
        "extension": ".odt"
    },
    {
        "label": "Ogg Audio",
        "type": "audio/ogg",
        "extension": ".oga"
    },
    {
        "label": "Ogg Video",
        "type": "video/ogg",
        "extension": ".ogv"
    },
    {
        "label": "Ogg",
        "type": "application/ogg",
        "extension": ".ogx"
    },
    {
        "label": "Microsoft OneNote",
        "type": "application/onenote",
        "extension": ".onetoc"
    },
    {
        "label": "Open eBook Publication Structure",
        "type": "application/oebps-package+xml",
        "extension": ".opf"
    },
    {
        "label": "Open Score Format",
        "type": "application/vnd.yamaha.openscoreformat",
        "extension": ".osf"
    },
    {
        "label": "OSFPVG",
        "type": "application/vnd.yamaha.openscoreformat.osfpvg+xml",
        "extension": ".osfpvg"
    },
    {
        "label": "OpenDocument Chart Template",
        "type": "application/vnd.oasis.opendocument.chart-template",
        "extension": ".otc"
    },
    {
        "label": "OpenType Font File",
        "type": "application/x-font-otf",
        "extension": ".otf"
    },
    {
        "label": "OpenDocument Graphics Template",
        "type": "application/vnd.oasis.opendocument.graphics-template",
        "extension": ".otg"
    },
    {
        "label": "Open Document Text Web",
        "type": "application/vnd.oasis.opendocument.text-web",
        "extension": ".oth"
    },
    {
        "label": "OpenDocument Image Template",
        "type": "application/vnd.oasis.opendocument.image-template",
        "extension": ".oti"
    },
    {
        "label": "OpenDocument Presentation Template",
        "type": "application/vnd.oasis.opendocument.presentation-template",
        "extension": ".otp"
    },
    {
        "label": "OpenDocument Spreadsheet Template",
        "type": "application/vnd.oasis.opendocument.spreadsheet-template",
        "extension": ".ots"
    },
    {
        "label": "OpenDocument Text Template",
        "type": "application/vnd.oasis.opendocument.text-template",
        "extension": ".ott"
    },
    {
        "label": "Open Office Extension",
        "type": "application/vnd.openofficeorg.extension",
        "extension": ".oxt"
    },
    {
        "label": "Pascal Source File",
        "type": "text/x-pascal",
        "extension": ".p"
    },
    {
        "label": "PKCS #10 – Certification Request Standard",
        "type": "application/pkcs10",
        "extension": ".p10"
    },
    {
        "label": "PKCS #12 – Personal Information Exchange Syntax Standard",
        "type": "application/x-pkcs12",
        "extension": ".p12"
    },
    {
        "label": "PKCS #7 – Cryptographic Message Syntax Standard (Certificates)",
        "type": "application/x-pkcs7-certificates",
        "extension": ".p7b"
    },
    {
        "label": "PKCS #7 – Cryptographic Message Syntax Standard",
        "type": "application/pkcs7-mime",
        "extension": ".p7m"
    },
    {
        "label": "PKCS #7 – Cryptographic Message Syntax Standard (Certificate Request Response)",
        "type": "application/x-pkcs7-certreqresp",
        "extension": ".p7r"
    },
    {
        "label": "PKCS #7 – Cryptographic Message Syntax Standard",
        "type": "application/pkcs7-signature",
        "extension": ".p7s"
    },
    {
        "label": "PKCS #8 – Private-Key Information Syntax Standard",
        "type": "application/pkcs8",
        "extension": ".p8"
    },
    {
        "label": "PawaaFILE",
        "type": "application/vnd.pawaafile",
        "extension": ".paw"
    },
    {
        "label": "PowerBuilder",
        "type": "application/vnd.powerbuilder6",
        "extension": ".pbd"
    },
    {
        "label": "Portable Bitmap Format",
        "type": "image/x-portable-bitmap",
        "extension": ".pbm"
    },
    {
        "label": "Portable Compiled Format",
        "type": "application/x-font-pcf",
        "extension": ".pcf"
    },
    {
        "label": "PCL 6 Enhanced (Formely PCL XL)",
        "type": "application/vnd.hp-pclxl",
        "extension": ".pclxl"
    },
    {
        "label": "PCX Image",
        "type": "image/x-pcx",
        "extension": ".pcx"
    },
    {
        "label": "PalmOS Data",
        "type": "application/vnd.palm",
        "extension": ".pdb"
    },
    {
        "label": "PostScript Fonts",
        "type": "application/x-font-type1",
        "extension": ".pfa"
    },
    {
        "label": "Portable Font Resource",
        "type": "application/font-tdpfr",
        "extension": ".pfr"
    },
    {
        "label": "Portable Graymap Format",
        "type": "image/x-portable-graymap",
        "extension": ".pgm"
    },
    {
        "label": "Portable Game Notation (Chess Games)",
        "type": "application/x-chess-pgn",
        "extension": ".pgn"
    },
    {
        "label": "Pretty Good Privacy – Signature",
        "type": "application/pgp-signature",
        "extension": ".pgp"
    },
    {
        "label": "PICT Image",
        "type": "image/x-pict",
        "extension": ".pic"
    },
    {
        "label": "Mobius Management Systems – Policy Definition Language File",
        "type": "application/vnd.mobius.plc",
        "extension": ".plc"
    },
    {
        "label": "PocketLearn Viewers",
        "type": "application/vnd.pocketlearn",
        "extension": ".plf"
    },
    {
        "label": "Pronunciation Lexicon Specification",
        "type": "application/pls+xml",
        "extension": ".pls"
    },
    {
        "label": "PosML",
        "type": "application/vnd.ctc-posml",
        "extension": ".pml"
    },
    {
        "label": "Portable Network Graphics (PNG)",
        "type": "image/png",
        "extension": ".png"
    },
    {
        "label": "Portable Anymap Image",
        "type": "image/x-portable-anymap",
        "extension": ".pnm"
    },
    {
        "label": "Microsoft Office – OOXML – Presentation Template",
        "type": "application/vnd.openxmlformats-officedocument.presentationml.template",
        "extension": ".potx"
    },
    {
        "label": "Microsoft PowerPoint – Add-in file",
        "type": "application/vnd.ms-powerpoint.addin.macroenabled.12",
        "extension": ".ppam"
    },
    {
        "label": "Portable Pixmap Format",
        "type": "image/x-portable-pixmap",
        "extension": ".ppm"
    },
    {
        "label": "Microsoft PowerPoint – Macro-Enabled Slide Show File",
        "type": "application/vnd.ms-powerpoint.slideshow.macroenabled.12",
        "extension": ".ppsm"
    },
    {
        "label": "Microsoft Office – OOXML – Presentation (Slideshow)",
        "type": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "extension": ".ppsx"
    },
    {
        "label": "Microsoft PowerPoint",
        "type": "application/vnd.ms-powerpoint",
        "extension": ".ppt"
    },
    {
        "label": "Microsoft PowerPoint – Macro-Enabled Presentation File",
        "type": "application/vnd.ms-powerpoint.presentation.macroenabled.12",
        "extension": ".pptm"
    },
    {
        "label": "Microsoft Office – OOXML – Presentation",
        "type": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "extension": ".pptx"
    },
    {
        "label": "Mobipocket",
        "type": "application/x-mobipocket-ebook",
        "extension": ".prc"
    },
    {
        "label": "PICSRules",
        "type": "application/pics-rules",
        "extension": ".prf"
    },
    {
        "label": "Photoshop Document",
        "type": "image/vnd.adobe.photoshop",
        "extension": ".psd"
    },
    {
        "label": "Portable Symmetric Key Container",
        "type": "application/pskc+xml",
        "extension": ".pskcxml"
    },
    {
        "label": "Microsoft Publisher",
        "type": "application/x-mspublisher",
        "extension": ".pub"
    },
    {
        "label": "Microsoft PlayReady Ecosystem",
        "type": "audio/vnd.ms-playready.media.pya",
        "extension": ".pya"
    },
    {
        "label": "Microsoft PlayReady Ecosystem Video",
        "type": "video/vnd.ms-playready.media.pyv",
        "extension": ".pyv"
    },
    {
        "label": "Mobile Information Device Profile",
        "type": "application/vnd.jcp.javame.midlet-rms",
        "extension": ".rms"
    },
    {
        "label": "Nokia Radio Application – Preset",
        "type": "application/vnd.nokia.radio-presets",
        "extension": ".rpss"
    },
    {
        "label": "Nokia Radio Application – Preset",
        "type": "application/vnd.nokia.radio-preset",
        "extension": ".rpst"
    },
    {
        "label": "Microsoft Schedule+",
        "type": "application/x-msschedule",
        "extension": ".scd"
    },
    {
        "label": "Microsoft PowerPoint – Macro-Enabled Open XML Slide",
        "type": "application/vnd.ms-powerpoint.slide.macroenabled.12",
        "extension": ".sldm"
    },
    {
        "label": "Microsoft Office – OOXML – Presentation (Slide)",
        "type": "application/vnd.openxmlformats-officedocument.presentationml.slide",
        "extension": ".sldx"
    },
    {
        "label": "Microsoft Trust UI Provider – Certificate Trust Link",
        "type": "application/vnd.ms-pki.stl",
        "extension": ".stl"
    },
    {
        "label": "Microsoft Office System Release Theme",
        "type": "application/vnd.ms-officetheme",
        "extension": ".thmx"
    },
    {
        "label": "MobileTV",
        "type": "application/vnd.tmobile-livetv",
        "extension": ".tmo"
    },
    {
        "label": "Microsoft Windows Terminal Services",
        "type": "application/x-msterminal",
        "extension": ".trm"
    },
    {
        "label": "Mobius Management Systems – Topic Index File",
        "type": "application/vnd.mobius.txf",
        "extension": ".txf"
    },
    {
        "label": "Microsoft Visio",
        "type": "application/vnd.visio",
        "extension": ".vsd"
    },
    {
        "label": "Microsoft Windows Media Audio Redirector",
        "type": "audio/x-ms-wax",
        "extension": ".wax"
    },
    {
        "label": "Microsoft Windows Media",
        "type": "video/x-ms-wm",
        "extension": ".wm"
    },
    {
        "label": "Microsoft Windows Media Audio",
        "type": "audio/x-ms-wma",
        "extension": ".wma"
    },
    {
        "label": "Microsoft Windows Media Player Download Package",
        "type": "application/x-ms-wmd",
        "extension": ".wmd"
    },
    {
        "label": "Microsoft Windows Metafile",
        "type": "application/x-msmetafile",
        "extension": ".wmf"
    },
    {
        "label": "Microsoft Windows Media Video",
        "type": "video/x-ms-wmv",
        "extension": ".wmv"
    },
    {
        "label": "Microsoft Windows Media Audio/Video Playlist",
        "type": "video/x-ms-wmx",
        "extension": ".wmx"
    },
    {
        "label": "Microsoft Windows Media Player Skin Package",
        "type": "application/x-ms-wmz",
        "extension": ".wmz"
    },
    {
        "label": "Microsoft Windows Media Player Playlist",
        "type": "application/vnd.ms-wpl",
        "extension": ".wpl"
    },
    {
        "label": "Microsoft Works",
        "type": "application/vnd.ms-works",
        "extension": ".wps"
    },
    {
        "label": "Microsoft Wordpad",
        "type": "application/x-mswrite",
        "extension": ".wri"
    },
    {
        "label": "Microsoft Windows Media Video Playlist",
        "type": "video/x-ms-wvx",
        "extension": ".wvx"
    },
    {
        "label": "Microsoft Silverlight",
        "type": "application/x-silverlight-app",
        "extension": ".xap"
    },
    {
        "label": "Microsoft XAML Browser Application",
        "type": "application/x-ms-xbap",
        "extension": ".xbap"
    },
    {
        "label": "Microsoft Office – OOXML – Spreadsheet",
        "type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "extension": ".xlsx"
    },
    {
        "label": "Microsoft Office – OOXML – Spreadsheet Teplate",
        "type": "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
        "extension": ".xltx"
    },
    {
        "label": "Microsoft XML Paper Specification",
        "type": "application/vnd.ms-xpsdocument",
        "extension": ".xps"
    },
    {
        "label": "Pretty Good Privacy",
        "type": "application/pgp-encrypted"
    },
    {
        "label": "Simply Accounting",
        "type": "application/vnd.accpac.simply.aso",
        "extension": ".aso"
    },
    {
        "label": "Atom Syndication Format",
        "type": "application/atom+xml",
        "extension": ".atom, .xml"
    },
    {
        "label": "Atom Publishing Protocol",
        "type": "application/atomcat+xml",
        "extension": ".atomcat"
    },
    {
        "label": "Atom Publishing Protocol Service Document",
        "type": "application/atomsvc+xml",
        "extension": ".atomsvc"
    },
    {
        "label": "Sun Audio – Au file format",
        "type": "audio/basic",
        "extension": ".au"
    },
    {
        "label": "Audio Video Interleave (AVI)",
        "type": "video/x-msvideo",
        "extension": ".avi"
    },
    {
        "label": "Binary CPIO Archive",
        "type": "application/x-bcpio",
        "extension": ".bcpio"
    },
    {
        "label": "Glyph Bitmap Distribution Format",
        "type": "application/x-font-bdf",
        "extension": ".bdf"
    },
    {
        "label": "SyncML – Device Management",
        "type": "application/vnd.syncml.dm+wbxml",
        "extension": ".bdm"
    },
    {
        "label": "RealVNC",
        "type": "application/vnd.realvnc.bed",
        "extension": ".bed"
    },
    {
        "label": "Fujitsu Oasys",
        "type": "application/vnd.fujitsu.oasysprs",
        "extension": ".bh2"
    },
    {
        "label": "Binary Data",
        "type": "application/octet-stream",
        "extension": ".bin"
    },
    {
        "label": "BMI Drawing Data Interchange",
        "type": "application/vnd.bmi",
        "extension": ".bmi"
    },
    {
        "label": "Bitmap Image File",
        "type": "image/bmp",
        "extension": ".bmp"
    },
    {
        "label": "BTIF",
        "type": "image/prs.btif",
        "extension": ".btif"
    },
    {
        "label": "Bzip Archive",
        "type": "application/x-bzip",
        "extension": ".bz"
    },
    {
        "label": "Bzip2 Archive",
        "type": "application/x-bzip2",
        "extension": ".bz2"
    },
    {
        "label": "C Source File",
        "type": "text/x-c",
        "extension": ".c"
    },
    {
        "label": "ClueTrust CartoMobile – Config",
        "type": "application/vnd.cluetrust.cartomobile-config",
        "extension": ".c11amc"
    },
    {
        "label": "ClueTrust CartoMobile – Config Package",
        "type": "application/vnd.cluetrust.cartomobile-config-pkg",
        "extension": ".c11amz"
    },
    {
        "label": "Clonk Game",
        "type": "application/vnd.clonk.c4group",
        "extension": ".c4g"
    },
    {
        "label": "Microsoft Cabinet File",
        "type": "application/vnd.ms-cab-compressed",
        "extension": ".cab"
    },
    {
        "label": "CURL Applet",
        "type": "application/vnd.curl.car",
        "extension": ".car"
    },
    {
        "label": "Voice Browser Call Control",
        "type": "application/ccxml+xml,",
        "extension": ".ccxml"
    },
    {
        "label": "CIM Database",
        "type": "application/vnd.contact.cmsg",
        "extension": ".cdbcmsg"
    },
    {
        "label": "MediaRemote",
        "type": "application/vnd.mediastation.cdkey",
        "extension": ".cdkey"
    },
    {
        "label": "Cloud Data Management Interface (CDMI) – Capability",
        "type": "application/cdmi-capability",
        "extension": ".cdmia"
    },
    {
        "label": "Cloud Data Management Interface (CDMI) – Contaimer",
        "type": "application/cdmi-container",
        "extension": ".cdmic"
    },
    {
        "label": "Cloud Data Management Interface (CDMI) – Domain",
        "type": "application/cdmi-domain",
        "extension": ".cdmid"
    },
    {
        "label": "Cloud Data Management Interface (CDMI) – Object",
        "type": "application/cdmi-object",
        "extension": ".cdmio"
    },
    {
        "label": "Cloud Data Management Interface (CDMI) – Queue",
        "type": "application/cdmi-queue",
        "extension": ".cdmiq"
    },
    {
        "label": "ChemDraw eXchange file",
        "type": "chemical/x-cdx",
        "extension": ".cdx"
    },
    {
        "label": "CambridgeSoft Chem Draw",
        "type": "application/vnd.chemdraw+xml",
        "extension": ".cdxml"
    },
    {
        "label": "Interactive Geometry Software Cinderella",
        "type": "application/vnd.cinderella",
        "extension": ".cdy"
    },
    {
        "label": "Internet Public Key Infrastructure – Certificate",
        "type": "application/pkix-cert",
        "extension": ".cer"
    },
    {
        "label": "Computer Graphics Metafile",
        "type": "image/cgm",
        "extension": ".cgm"
    },
    {
        "label": "Microsoft Html Help File",
        "type": "application/vnd.ms-htmlhelp",
        "extension": ".chm"
    },
    {
        "label": "KDE KOffice Office Suite – KChart",
        "type": "application/vnd.kde.kchart",
        "extension": ".chrt"
    },
    {
        "label": "Crystallographic Interchange Format",
        "type": "chemical/x-cif",
        "extension": ".cif"
    },
    {
        "label": "Microsoft Artgalry",
        "type": "application/vnd.ms-artgalry",
        "extension": ".cil"
    },
    {
        "label": "Claymore Data Files",
        "type": "application/vnd.claymore",
        "extension": ".cla"
    },
    {
        "label": "Java Bytecode File",
        "type": "application/java-vm",
        "extension": ".class"
    },
    {
        "label": "CrickSoftware – Clicker – Keyboard",
        "type": "application/vnd.crick.clicker.keyboard",
        "extension": ".clkk"
    },
    {
        "label": "CrickSoftware – Clicker – Palette",
        "type": "application/vnd.crick.clicker.palette",
        "extension": ".clkp"
    },
    {
        "label": "CrickSoftware – Clicker – Template",
        "type": "application/vnd.crick.clicker.template",
        "extension": ".clkt"
    },
    {
        "label": "CrickSoftware – Clicker – Wordbank",
        "type": "application/vnd.crick.clicker.wordbank",
        "extension": ".clkw"
    },
    {
        "label": "CrickSoftware – Clicker",
        "type": "application/vnd.crick.clicker",
        "extension": ".clkx"
    },
    {
        "label": "Microsoft Clipboard Clip",
        "type": "application/x-msclip",
        "extension": ".clp"
    },
    {
        "label": "CosmoCaller",
        "type": "application/vnd.cosmocaller",
        "extension": ".cmc"
    },
    {
        "label": "CrystalMaker Data Format",
        "type": "chemical/x-cmdf",
        "extension": ".cmdf"
    },
    {
        "label": "Chemical Markup Language",
        "type": "chemical/x-cml",
        "extension": ".cml"
    },
    {
        "label": "CustomMenu",
        "type": "application/vnd.yellowriver-custom-menu",
        "extension": ".cmp"
    },
    {
        "label": "Corel Metafile Exchange (CMX)",
        "type": "image/x-cmx",
        "extension": ".cmx"
    },
    {
        "label": "Blackberry COD File",
        "type": "application/vnd.rim.cod",
        "extension": ".cod"
    },
    {
        "label": "CPIO Archive",
        "type": "application/x-cpio",
        "extension": ".cpio"
    },
    {
        "label": "Compact Pro",
        "type": "application/mac-compactpro",
        "extension": ".cpt"
    },
    {
        "label": "Microsoft Information Card",
        "type": "application/x-mscardfile",
        "extension": ".crd"
    },
    {
        "label": "Internet Public Key Infrastructure – Certificate Revocation Lists",
        "type": "application/pkix-crl",
        "extension": ".crl"
    },
    {
        "label": "CryptoNote",
        "type": "application/vnd.rig.cryptonote",
        "extension": ".cryptonote"
    },
    {
        "label": "C Shell Script",
        "type": "application/x-csh",
        "extension": ".csh"
    },
    {
        "label": "Chemical Style Markup Language",
        "type": "chemical/x-csml",
        "extension": ".csml"
    },
    {
        "label": "Sixth Floor Media – CommonSpace",
        "type": "application/vnd.commonspace",
        "extension": ".csp"
    },
    {
        "label": "Cascading Style Sheets (CSS)",
        "type": "text/css",
        "extension": ".css"
    },
    {
        "label": "Comma-Seperated Values",
        "type": "text/csv",
        "extension": ".csv"
    },
    {
        "label": "CU-SeeMe",
        "type": "application/cu-seeme",
        "extension": ".cu"
    },
    {
        "label": "Curl – Applet",
        "type": "text/vnd.curl",
        "extension": ".curl"
    },
    {
        "label": "CU-Writer",
        "type": "application/prs.cww",
        "extension": ".cww"
    },
    {
        "label": "COLLADA",
        "type": "model/vnd.collada+xml",
        "extension": ".dae"
    },
    {
        "label": "Web Distributed Authoring and Versioning",
        "type": "application/davmount+xml",
        "extension": ".davmount"
    },
    {
        "label": "Curl – Detached Applet",
        "type": "text/vnd.curl.dcurl",
        "extension": ".dcurl"
    },
    {
        "label": "Fujitsu – Xerox 2D CAD Data",
        "type": "application/vnd.fujixerox.ddd",
        "extension": ".ddd"
    },
    {
        "label": "Debian Package",
        "type": "application/x-debian-package",
        "extension": ".deb"
    },
    {
        "label": "X.509 Certificate",
        "type": "application/x-x509-ca-cert",
        "extension": ".der"
    },
    {
        "label": "DreamFactory",
        "type": "application/vnd.dreamfactory",
        "extension": ".dfac"
    },
    {
        "label": "DjVu",
        "type": "image/vnd.djvu",
        "extension": ".djvu"
    },
    {
        "label": "Micosoft Word – Macro-Enabled Document",
        "type": "application/vnd.ms-word.document.macroenabled.12",
        "extension": ".docm"
    },
    {
        "label": "Micosoft Word – Macro-Enabled Template",
        "type": "application/vnd.ms-word.template.macroenabled.12",
        "extension": ".dotm"
    },
    {
        "label": "DPGraph",
        "type": "application/vnd.dpgraph",
        "extension": ".dpg"
    },
    {
        "label": "DRA Audio",
        "type": "audio/vnd.dra",
        "extension": ".dra"
    },
    {
        "label": "Data Structure for the Security Suitability of Cryptographic Algorithms",
        "type": "application/dssc+der",
        "extension": ".dssc"
    },
    {
        "label": "Digital Talking Book",
        "type": "application/x-dtbook+xml",
        "extension": ".dtb"
    },
    {
        "label": "Document Type Definition",
        "type": "application/xml-dtd",
        "extension": ".dtd"
    },
    {
        "label": "DTS Audio",
        "type": "audio/vnd.dts",
        "extension": ".dts"
    },
    {
        "label": "DTS High Definition Audio",
        "type": "audio/vnd.dts.hd",
        "extension": ".dtshd"
    },
    {
        "label": "Device Independent File Format (DVI)",
        "type": "application/x-dvi",
        "extension": ".dvi"
    },
    {
        "label": "Autodesk Design Web Format (DWF)",
        "type": "model/vnd.dwf",
        "extension": ".dwf"
    },
    {
        "label": "DWG Drawing",
        "type": "image/vnd.dwg",
        "extension": ".dwg"
    },
    {
        "label": "AutoCAD DXF",
        "type": "image/vnd.dxf",
        "extension": ".dxf"
    },
    {
        "label": "TIBCO Spotfire",
        "type": "application/vnd.spotfire.dxp",
        "extension": ".dxp"
    },
    {
        "label": "Email Message",
        "type": "message/rfc822",
        "extension": ".eml"
    },
    {
        "label": "Extensible MultiModal Annotation",
        "type": "application/emma+xml",
        "extension": ".emma"
    },
    {
        "label": "Digital Winds Music",
        "type": "audio/vnd.digital-winds",
        "extension": ".eol"
    },
    {
        "label": "Microsoft Embedded OpenType",
        "type": "application/vnd.ms-fontobject",
        "extension": ".eot"
    },
    {
        "label": "Electronic Publication",
        "type": "application/epub+zip",
        "extension": ".epub"
    },
    {
        "label": "ECMAScript",
        "type": "application/ecmascript",
        "extension": ".es"
    },
    {
        "label": "MICROSEC e-Szign¢",
        "type": "application/vnd.eszigno3+xml",
        "extension": ".es3"
    },
    {
        "label": "QUASS Stream Player",
        "type": "application/vnd.epson.esf",
        "extension": ".esf"
    },
    {
        "label": "Setext",
        "type": "text/x-setext",
        "extension": ".etx"
    },
    {
        "label": "Microsoft Application",
        "type": "application/x-msdownload",
        "extension": ".exe"
    },
    {
        "label": "Efficient XML Interchange",
        "type": "application/exi",
        "extension": ".exi"
    },
    {
        "label": "EZPix Secure Photo Album",
        "type": "application/vnd.ezpix-album",
        "extension": ".ez2"
    },
    {
        "label": "EZPix Secure Photo Album",
        "type": "application/vnd.ezpix-package",
        "extension": ".ez3"
    },
    {
        "label": "Fortran Source File",
        "type": "text/x-fortran",
        "extension": ".f"
    },
    {
        "label": "Flash Video",
        "type": "video/x-f4v",
        "extension": ".f4v"
    },
    {
        "label": "FastBid Sheet",
        "type": "image/vnd.fastbidsheet",
        "extension": ".fbs"
    },
    {
        "label": "International Society for Advancement of Cytometry",
        "type": "application/vnd.isac.fcs",
        "extension": ".fcs"
    },
    {
        "label": "Forms Data Format",
        "type": "application/vnd.fdf",
        "extension": ".fdf"
    },
    {
        "label": "FCS Express Layout Link",
        "type": "application/vnd.denovo.fcselayout-link",
        "extension": ".fe_launch"
    },
    {
        "label": "Fujitsu Oasys",
        "type": "application/vnd.fujitsu.oasysgp",
        "extension": ".fg5"
    },
    {
        "label": "FreeHand MX",
        "type": "image/x-freehand",
        "extension": ".fh"
    },
    {
        "label": "Xfig",
        "type": "application/x-xfig",
        "extension": ".fig"
    },
    {
        "label": "FLI/FLC Animation Format",
        "type": "video/x-fli",
        "extension": ".fli"
    },
    {
        "label": "Micrografx",
        "type": "application/vnd.micrografx.flo",
        "extension": ".flo"
    },
    {
        "label": "Flash Video",
        "type": "video/x-flv",
        "extension": ".flv"
    },
    {
        "label": "KDE KOffice Office Suite – Kivio",
        "type": "application/vnd.kde.kivio",
        "extension": ".flw"
    },
    {
        "label": "FLEXSTOR",
        "type": "text/vnd.fmi.flexstor",
        "extension": ".flx"
    },
    {
        "label": "FrameMaker Normal Format",
        "type": "application/vnd.framemaker",
        "extension": ".fm"
    },
    {
        "label": "Frogans Player",
        "type": "application/vnd.frogans.fnc",
        "extension": ".fnc"
    },
    {
        "label": "FlashPix",
        "type": "image/vnd.fpx",
        "extension": ".fpx"
    },
    {
        "label": "Friendly Software Corporation",
        "type": "application/vnd.fsc.weblaunch",
        "extension": ".fsc"
    },
    {
        "label": "FAST Search &amp; Transfer ASA",
        "type": "image/vnd.fst",
        "extension": ".fst"
    },
    {
        "label": "FluxTime Clip",
        "type": "application/vnd.fluxtime.clip",
        "extension": ".ftc"
    },
    {
        "label": "FAST Search &amp; Transfer ASA",
        "type": "video/vnd.fvt",
        "extension": ".fvt"
    },
    {
        "label": "FuzzySheet",
        "type": "application/vnd.fuzzysheet",
        "extension": ".fzs"
    },
    {
        "label": "GeoplanW",
        "type": "application/vnd.geoplan",
        "extension": ".g2w"
    },
    {
        "label": "G3 Fax Image",
        "type": "image/g3fax",
        "extension": ".g3"
    },
    {
        "label": "GeospacW",
        "type": "application/vnd.geospace",
        "extension": ".g3w"
    },
    {
        "label": "Groove – Account",
        "type": "application/vnd.groove-account",
        "extension": ".gac"
    },
    {
        "label": "Geometric Description Language (GDL)",
        "type": "model/vnd.gdl",
        "extension": ".gdl"
    },
    {
        "label": "DynaGeo",
        "type": "application/vnd.dynageo",
        "extension": ".geo"
    },
    {
        "label": "GeoMetry Explorer",
        "type": "application/vnd.geometry-explorer",
        "extension": ".gex"
    },
    {
        "label": "GeoGebra",
        "type": "application/vnd.geogebra.file",
        "extension": ".ggb"
    },
    {
        "label": "GeoGebra",
        "type": "application/vnd.geogebra.tool",
        "extension": ".ggt"
    },
    {
        "label": "Groove – Help",
        "type": "application/vnd.groove-help",
        "extension": ".ghf"
    },
    {
        "label": "Graphics Interchange Format",
        "type": "image/gif",
        "extension": ".gif"
    },
    {
        "label": "Groove – Identity Message",
        "type": "application/vnd.groove-identity-message",
        "extension": ".gim"
    },
    {
        "label": "GameMaker ActiveX",
        "type": "application/vnd.gmx",
        "extension": ".gmx"
    },
    {
        "label": "Gnumeric",
        "type": "application/x-gnumeric",
        "extension": ".gnumeric"
    },
    {
        "label": "GrafEq",
        "type": "application/vnd.grafeq",
        "extension": ".gqf"
    },
    {
        "label": "Speech Recognition Grammar Specification",
        "type": "application/srgs",
        "extension": ".gram"
    },
    {
        "label": "Groove – Injector",
        "type": "application/vnd.groove-injector",
        "extension": ".grv"
    },
    {
        "label": "Speech Recognition Grammar Specification – XML",
        "type": "application/srgs+xml",
        "extension": ".grxml"
    },
    {
        "label": "Ghostscript Font",
        "type": "application/x-font-ghostscript",
        "extension": ".gsf"
    },
    {
        "label": "GNU Tar Files",
        "type": "application/x-gtar",
        "extension": ".gtar"
    },
    {
        "label": "Groove – Tool Message",
        "type": "application/vnd.groove-tool-message",
        "extension": ".gtm"
    },
    {
        "label": "Gen-Trix Studio",
        "type": "model/vnd.gtw",
        "extension": ".gtw"
    },
    {
        "label": "Graphviz",
        "type": "text/vnd.graphviz",
        "extension": ".gv"
    },
    {
        "label": "GEONExT and JSXGraph",
        "type": "application/vnd.geonext",
        "extension": ".gxt"
    },
    {
        "label": "H.261",
        "type": "video/h261",
        "extension": ".h261"
    },
    {
        "label": "H.263",
        "type": "video/h263",
        "extension": ".h263"
    },
    {
        "label": "H.264",
        "type": "video/h264",
        "extension": ".h264"
    },
    {
        "label": "Hypertext Application Language",
        "type": "application/vnd.hal+xml",
        "extension": ".hal"
    },
    {
        "label": "Homebanking Computer Interface (HBCI)",
        "type": "application/vnd.hbci",
        "extension": ".hbci"
    },
    {
        "label": "Hierarchical Data Format",
        "type": "application/x-hdf",
        "extension": ".hdf"
    },
    {
        "label": "WinHelp",
        "type": "application/winhlp",
        "extension": ".hlp"
    },
    {
        "label": "HP-GL/2 and HP RTL",
        "type": "application/vnd.hp-hpgl",
        "extension": ".hpgl"
    },
    {
        "label": "Hewlett Packard Instant Delivery",
        "type": "application/vnd.hp-hpid",
        "extension": ".hpid"
    },
    {
        "label": "Hewlett-Packard’s WebPrintSmart",
        "type": "application/vnd.hp-hps",
        "extension": ".hps"
    },
    {
        "label": "Macintosh BinHex 4.0",
        "type": "application/mac-binhex40",
        "extension": ".hqx"
    },
    {
        "label": "Kenamea App",
        "type": "application/vnd.kenameaapp",
        "extension": ".htke"
    },
    {
        "label": "HyperText Markup Language (HTML)",
        "type": "text/html",
        "extension": ".html"
    },
    {
        "label": "HV Voice Dictionary",
        "type": "application/vnd.yamaha.hv-dic",
        "extension": ".hvd"
    },
    {
        "label": "HV Voice Parameter",
        "type": "application/vnd.yamaha.hv-voice",
        "extension": ".hvp"
    },
    {
        "label": "HV Script",
        "type": "application/vnd.yamaha.hv-script",
        "extension": ".hvs"
    },
    {
        "label": "Interactive Geometry Software",
        "type": "application/vnd.intergeo",
        "extension": ".i2g"
    },
    {
        "label": "ICC profile",
        "type": "application/vnd.iccprofile",
        "extension": ".icc"
    },
    {
        "label": "CoolTalk",
        "type": "x-conference/x-cooltalk",
        "extension": ".ice"
    },
    {
        "label": "Icon Image",
        "type": "image/x-icon",
        "extension": ".ico"
    },
    {
        "label": "iCalendar",
        "type": "text/calendar",
        "extension": ".ics"
    },
    {
        "label": "Image Exchange Format",
        "type": "image/ief",
        "extension": ".ief"
    },
    {
        "label": "Shana Informed Filler",
        "type": "application/vnd.shana.informed.formdata",
        "extension": ".ifm"
    },
    {
        "label": "igLoader",
        "type": "application/vnd.igloader",
        "extension": ".igl"
    },
    {
        "label": "IOCOM Visimeet",
        "type": "application/vnd.insors.igm",
        "extension": ".igm"
    },
    {
        "label": "Initial Graphics Exchange Specification (IGES)",
        "type": "model/iges",
        "extension": ".igs"
    },
    {
        "label": "Micrografx iGrafx Professional",
        "type": "application/vnd.micrografx.igx",
        "extension": ".igx"
    },
    {
        "label": "Shana Informed Filler",
        "type": "application/vnd.shana.informed.interchange",
        "extension": ".iif"
    },
    {
        "label": "Simply Accounting – Data Import",
        "type": "application/vnd.accpac.simply.imp",
        "extension": ".imp"
    },
    {
        "label": "Microsoft Class Server",
        "type": "application/vnd.ms-ims",
        "extension": ".ims"
    },
    {
        "label": "Internet Protocol Flow Information Export",
        "type": "application/ipfix",
        "extension": ".ipfix"
    },
    {
        "label": "Shana Informed Filler",
        "type": "application/vnd.shana.informed.package",
        "extension": ".ipk"
    },
    {
        "label": "IBM DB2 Rights Manager",
        "type": "application/vnd.ibm.rights-management",
        "extension": ".irm"
    },
    {
        "label": "iRepository / Lucidoc Editor",
        "type": "application/vnd.irepository.package+xml",
        "extension": ".irp"
    },
    {
        "label": "Shana Informed Filler",
        "type": "application/vnd.shana.informed.formtemplate",
        "extension": ".itp"
    },
    {
        "label": "ImmerVision PURE Players",
        "type": "application/vnd.immervision-ivp",
        "extension": ".ivp"
    },
    {
        "label": "ImmerVision PURE Players",
        "type": "application/vnd.immervision-ivu",
        "extension": ".ivu"
    },
    {
        "label": "J2ME App Descriptor",
        "type": "text/vnd.sun.j2me.app-descriptor",
        "extension": ".jad"
    },
    {
        "label": "Lightspeed Audio Lab",
        "type": "application/vnd.jam",
        "extension": ".jam"
    },
    {
        "label": "Java Archive",
        "type": "application/java-archive",
        "extension": ".jar"
    },
    {
        "label": "Java Source File",
        "type": "text/x-java-source,java",
        "extension": ".java"
    },
    {
        "label": "RhymBox",
        "type": "application/vnd.jisp",
        "extension": ".jisp"
    },
    {
        "label": "HP Indigo Digital Press – Job Layout Languate",
        "type": "application/vnd.hp-jlyt",
        "extension": ".jlt"
    },
    {
        "label": "Java Network Launching Protocol",
        "type": "application/x-java-jnlp-file",
        "extension": ".jnlp"
    },
    {
        "label": "Joda Archive",
        "type": "application/vnd.joost.joda-archive",
        "extension": ".joda"
    },
    {
        "label": "JPEG Image",
        "type": "image/jpeg",
        "extension": ".jpeg, .jpg"
    },
    {
        "label": "JPGVideo",
        "type": "video/jpeg",
        "extension": ".jpgv"
    },
    {
        "label": "JPEG 2000 Compound Image File Format",
        "type": "video/jpm",
        "extension": ".jpm"
    },
    {
        "label": "JavaScript",
        "type": "application/javascript",
        "extension": ".js"
    },
    {
        "label": "JavaScript Object Notation (JSON)",
        "type": "application/json",
        "extension": ".json"
    },
    {
        "label": "KDE KOffice Office Suite – Karbon",
        "type": "application/vnd.kde.karbon",
        "extension": ".karbon"
    },
    {
        "label": "KDE KOffice Office Suite – Kformula",
        "type": "application/vnd.kde.kformula",
        "extension": ".kfo"
    },
    {
        "label": "Kidspiration",
        "type": "application/vnd.kidspiration",
        "extension": ".kia"
    },
    {
        "label": "Google Earth – KML",
        "type": "application/vnd.google-earth.kml+xml",
        "extension": ".kml"
    },
    {
        "label": "Google Earth – Zipped KML",
        "type": "application/vnd.google-earth.kmz",
        "extension": ".kmz"
    },
    {
        "label": "Kinar Applications",
        "type": "application/vnd.kinar",
        "extension": ".kne"
    },
    {
        "label": "KDE KOffice Office Suite – Kontour",
        "type": "application/vnd.kde.kontour",
        "extension": ".kon"
    },
    {
        "label": "KDE KOffice Office Suite – Kpresenter",
        "type": "application/vnd.kde.kpresenter",
        "extension": ".kpr"
    },
    {
        "label": "KDE KOffice Office Suite – Kspread",
        "type": "application/vnd.kde.kspread",
        "extension": ".ksp"
    },
    {
        "label": "Kahootz",
        "type": "application/vnd.kahootz",
        "extension": ".ktz"
    },
    {
        "label": "KDE KOffice Office Suite – Kword",
        "type": "application/vnd.kde.kword",
        "extension": ".kwd"
    },
    {
        "label": "Laser App Enterprise",
        "type": "application/vnd.las.las+xml",
        "extension": ".lasxml"
    },
    {
        "label": "LaTeX",
        "type": "application/x-latex",
        "extension": ".latex"
    },
    {
        "label": "Life Balance – Desktop Edition",
        "type": "application/vnd.llamagraphics.life-balance.desktop",
        "extension": ".lbd"
    },
    {
        "label": "Life Balance – Exchange Format",
        "type": "application/vnd.llamagraphics.life-balance.exchange+xml",
        "extension": ".lbe"
    },
    {
        "label": "ROUTE 66 Location Based Services",
        "type": "application/vnd.route66.link66+xml",
        "extension": ".link66"
    },
    {
        "label": "Microsoft Learning Resource Module",
        "type": "application/vnd.ms-lrm",
        "extension": ".lrm"
    },
    {
        "label": "Frogans Player",
        "type": "application/vnd.frogans.ltf",
        "extension": ".ltf"
    },
    {
        "label": "Lucent Voice",
        "type": "audio/vnd.lucent.voice",
        "extension": ".lvp"
    },
    {
        "label": "Lotus Wordpro",
        "type": "application/vnd.lotus-wordpro",
        "extension": ".lwp"
    },
    {
        "label": "M3U (Multimedia Playlist)",
        "type": "audio/x-mpegurl",
        "extension": ".m3u"
    },
    {
        "label": "M4v",
        "type": "video/x-m4v",
        "extension": ".m4v"
    },
    {
        "label": "Mathematica Notebooks",
        "type": "application/mathematica",
        "extension": ".ma"
    },
    {
        "label": "Metadata Authority Description Schema",
        "type": "application/mads+xml",
        "extension": ".mads"
    },
    {
        "label": "EcoWin Chart",
        "type": "application/vnd.ecowin.chart",
        "extension": ".mag"
    },
    {
        "label": "Mathematical Markup Language",
        "type": "application/mathml+xml",
        "extension": ".mathml"
    },
    {
        "label": "Mbox database files",
        "type": "application/mbox",
        "extension": ".mbox"
    },
    {
        "label": "MedCalc",
        "type": "application/vnd.medcalcdata",
        "extension": ".mc1"
    },
    {
        "label": "Micro CADAM Helix D&amp;D",
        "type": "application/vnd.mcd",
        "extension": ".mcd"
    },
    {
        "label": "Curl – Manifest File",
        "type": "text/vnd.curl.mcurl",
        "extension": ".mcurl"
    },
    {
        "label": "Microsoft Access",
        "type": "application/x-msaccess",
        "extension": ".mdb"
    },
    {
        "label": "Microsoft Document Imaging Format",
        "type": "image/vnd.ms-modi",
        "extension": ".mdi"
    },
    {
        "label": "Metalink",
        "type": "application/metalink4+xml",
        "extension": ".meta4"
    },
    {
        "label": "Metadata Encoding and Transmission Standard",
        "type": "application/mets+xml",
        "extension": ".mets"
    },
    {
        "label": "Melody Format for Mobile Platform",
        "type": "application/vnd.mfmp",
        "extension": ".mfm"
    },
    {
        "label": "MapGuide DBXML",
        "type": "application/vnd.osgeo.mapguide.package",
        "extension": ".mgp"
    },
    {
        "label": "EFI Proteus",
        "type": "application/vnd.proteus.magazine",
        "extension": ".mgz"
    },
    {
        "label": "FrameMaker Interchange Format",
        "type": "application/vnd.mif",
        "extension": ".mif"
    },
    {
        "label": "Dolby Meridian Lossless Packing",
        "type": "application/vnd.dolby.mlp",
        "extension": ".mlp"
    },
    {
        "label": "Karaoke on Chipnuts Chipsets",
        "type": "application/vnd.chipnuts.karaoke-mmd",
        "extension": ".mmd"
    },
    {
        "label": "SMAF File",
        "type": "application/vnd.smaf",
        "extension": ".mmf"
    },
    {
        "label": "EDMICS 2000",
        "type": "image/vnd.fujixerox.edmics-mmr",
        "extension": ".mmr"
    },
    {
        "label": "Microsoft Money",
        "type": "application/x-msmoney",
        "extension": ".mny"
    },
    {
        "label": "Metadata Object Description Schema",
        "type": "application/mods+xml",
        "extension": ".mods"
    },
    {
        "label": "SGI Movie",
        "type": "video/x-sgi-movie",
        "extension": ".movie"
    },
    {
        "label": "Blueice Research Multipass",
        "type": "application/vnd.blueice.multipass",
        "extension": ".mpm"
    },
    {
        "label": "MARC Formats",
        "type": "application/marc",
        "extension": ".mrc"
    },
    {
        "label": "MARC21 XML Schema",
        "type": "application/marcxml+xml",
        "extension": ".mrcx"
    },
    {
        "label": "Media Server Control Markup Language",
        "type": "application/mediaservercontrol+xml",
        "extension": ".mscml"
    },
    {
        "label": "QUASS Stream Player",
        "type": "application/vnd.epson.msf",
        "extension": ".msf"
    },
    {
        "label": "Mesh Data Type",
        "type": "model/mesh",
        "extension": ".msh"
    },
    {
        "label": "Virtue MTS",
        "type": "model/vnd.mts",
        "extension": ".mts"
    },
    {
        "label": "Recordare Applications",
        "type": "application/vnd.recordare.musicxml+xml",
        "extension": ".musicxml"
    },
    {
        "label": "Microsoft MediaView",
        "type": "application/x-msmediaview",
        "extension": ".mvb"
    },
    {
        "label": "Medical Waveform Encoding Format",
        "type": "application/vnd.mfer",
        "extension": ".mwf"
    },
    {
        "label": "Material Exchange Format",
        "type": "application/mxf",
        "extension": ".mxf"
    },
    {
        "label": "Recordare Applications",
        "type": "application/vnd.recordare.musicxml",
        "extension": ".mxl"
    },
    {
        "label": "Triscape Map Explorer",
        "type": "application/vnd.triscape.mxs",
        "extension": ".mxs"
    },
    {
        "label": "Mathematica Notebook Player",
        "type": "application/vnd.wolfram.player",
        "extension": ".nbp"
    },
    {
        "label": "Enliven Viewer",
        "type": "application/vnd.enliven",
        "extension": ".nml"
    },
    {
        "label": "FlashPix",
        "type": "image/vnd.net-fpx",
        "extension": ".npx"
    },
    {
        "label": "Lotus Notes",
        "type": "application/vnd.lotus-notes",
        "extension": ".nsf"
    },
    {
        "label": "Fujitsu Oasys",
        "type": "application/vnd.fujitsu.oasys2",
        "extension": ".oa2"
    },
    {
        "label": "Fujitsu Oasys",
        "type": "application/vnd.fujitsu.oasys3",
        "extension": ".oa3"
    },
    {
        "label": "Fujitsu Oasys",
        "type": "application/vnd.fujitsu.oasys",
        "extension": ".oas"
    },
    {
        "label": "Lotus Organizer",
        "type": "application/vnd.lotus-organizer",
        "extension": ".org"
    },
    {
        "label": "BAS Partitur Format",
        "type": "text/plain-bas",
        "extension": ".par"
    },
    {
        "label": "HP Printer Command Language",
        "type": "application/vnd.hp-pcl",
        "extension": ".pcl"
    },
    {
        "label": "CURL Applet",
        "type": "application/vnd.curl.pcurl",
        "extension": ".pcurl"
    },
    {
        "label": "Internet Public Key Infrastructure – Certificate Management Protocole",
        "type": "application/pkixcmp",
        "extension": ".pki"
    },
    {
        "label": "Internet Public Key Infrastructure – Certification Path",
        "type": "application/pkix-pkipath",
        "extension": ".pkipath"
    },
    {
        "label": "MacPorts Port System",
        "type": "application/vnd.macports.portpkg",
        "extension": ".portpkg"
    },
    {
        "label": "Micosoft PowerPoint – Macro-Enabled Template File",
        "type": "application/vnd.ms-powerpoint.template.macroenabled.12",
        "extension": ".potm"
    },
    {
        "label": "Lotus Freelance",
        "type": "application/vnd.lotus-freelance",
        "extension": ".pre"
    },
    {
        "label": "PSF Fonts",
        "type": "application/x-font-linux-psf",
        "extension": ".psf"
    },
    {
        "label": "Princeton Video Image",
        "type": "application/vnd.pvi.ptid1",
        "extension": ".ptid"
    },
    {
        "label": "QuickAnime Player",
        "type": "application/vnd.epson.quickanime",
        "extension": ".qam"
    },
    {
        "label": "Open Financial Exchange",
        "type": "application/vnd.intu.qbo",
        "extension": ".qbo"
    },
    {
        "label": "Quicken",
        "type": "application/vnd.intu.qfx",
        "extension": ".qfx"
    },
    {
        "label": "PubliShare Objects",
        "type": "application/vnd.publishare-delta-tree",
        "extension": ".qps"
    },
    {
        "label": "Quicktime Video",
        "type": "video/quicktime",
        "extension": ".qt"
    },
    {
        "label": "QuarkXpress",
        "type": "application/vnd.quark.quarkxpress",
        "extension": ".qxd"
    },
    {
        "label": "Real Audio Sound",
        "type": "audio/x-pn-realaudio",
        "extension": ".ram"
    },
    {
        "label": "RAR Archive",
        "type": "application/x-rar-compressed",
        "extension": ".rar"
    },
    {
        "label": "CMU Image",
        "type": "image/x-cmu-raster",
        "extension": ".ras"
    },
    {
        "label": "IP Unplugged Roaming Client",
        "type": "application/vnd.ipunplugged.rcprofile",
        "extension": ".rcprofile"
    },
    {
        "label": "Resource Description Framework",
        "type": "application/rdf+xml",
        "extension": ".rdf"
    },
    {
        "label": "RemoteDocs R-Viewer",
        "type": "application/vnd.data-vision.rdz",
        "extension": ".rdz"
    },
    {
        "label": "BusinessObjects",
        "type": "application/vnd.businessobjects",
        "extension": ".rep"
    },
    {
        "label": "Digital Talking Book – Resource File",
        "type": "application/x-dtbresource+xml",
        "extension": ".res"
    },
    {
        "label": "Silicon Graphics RGB Bitmap",
        "type": "image/x-rgb",
        "extension": ".rgb"
    },
    {
        "label": "IMS Networks",
        "type": "application/reginfo+xml",
        "extension": ".rif"
    },
    {
        "label": "Hit’n’Mix",
        "type": "audio/vnd.rip",
        "extension": ".rip"
    },
    {
        "label": "XML Resource Lists",
        "type": "application/resource-lists+xml",
        "extension": ".rl"
    },
    {
        "label": "EDMICS 2000",
        "type": "image/vnd.fujixerox.edmics-rlc",
        "extension": ".rlc"
    },
    {
        "label": "XML Resource Lists Diff",
        "type": "application/resource-lists-diff+xml",
        "extension": ".rld"
    },
    {
        "label": "RealMedia",
        "type": "application/vnd.rn-realmedia",
        "extension": ".rm"
    },
    {
        "label": "Real Audio Sound",
        "type": "audio/x-pn-realaudio-plugin",
        "extension": ".rmp"
    },
    {
        "label": "Relax NG Compact Syntax",
        "type": "application/relax-ng-compact-syntax",
        "extension": ".rnc"
    },
    {
        "label": "RetroPlatform Player",
        "type": "application/vnd.cloanto.rp9",
        "extension": ".rp9"
    },
    {
        "label": "SPARQL – Query",
        "type": "application/sparql-query",
        "extension": ".rq"
    },
    {
        "label": "XML Resource Lists",
        "type": "application/rls-services+xml",
        "extension": ".rs"
    },
    {
        "label": "Really Simple Discovery",
        "type": "application/rsd+xml",
        "extension": ".rsd"
    },
    {
        "label": "RSS – Really Simple Syndication",
        "type": "application/rss+xml",
        "extension": ".rss, .xml"
    },
    {
        "label": "Rich Text Format",
        "type": "application/rtf",
        "extension": ".rtf"
    },
    {
        "label": "Rich Text Format (RTF)",
        "type": "text/richtext",
        "extension": ".rtx"
    },
    {
        "label": "SMAF Audio",
        "type": "application/vnd.yamaha.smaf-audio",
        "extension": ".saf"
    },
    {
        "label": "Systems Biology Markup Language",
        "type": "application/sbml+xml",
        "extension": ".sbml"
    },
    {
        "label": "IBM Electronic Media Management System – Secure Container",
        "type": "application/vnd.ibm.secure-container",
        "extension": ".sc"
    },
    {
        "label": "Lotus Screencam",
        "type": "application/vnd.lotus-screencam",
        "extension": ".scm"
    },
    {
        "label": "Server-Based Certificate Validation Protocol – Validation Request",
        "type": "application/scvp-cv-request",
        "extension": ".scq"
    },
    {
        "label": "Server-Based Certificate Validation Protocol – Validation Response",
        "type": "application/scvp-cv-response",
        "extension": ".scs"
    },
    {
        "label": "Curl – Source Code",
        "type": "text/vnd.curl.scurl",
        "extension": ".scurl"
    },
    {
        "label": "StarOffice – Draw",
        "type": "application/vnd.stardivision.draw",
        "extension": ".sda"
    },
    {
        "label": "StarOffice – Calc",
        "type": "application/vnd.stardivision.calc",
        "extension": ".sdc"
    },
    {
        "label": "StarOffice – Impress",
        "type": "application/vnd.stardivision.impress",
        "extension": ".sdd"
    },
    {
        "label": "SudokuMagic",
        "type": "application/vnd.solent.sdkm+xml",
        "extension": ".sdkm"
    },
    {
        "label": "Session Description Protocol",
        "type": "application/sdp",
        "extension": ".sdp"
    },
    {
        "label": "StarOffice – Writer",
        "type": "application/vnd.stardivision.writer",
        "extension": ".sdw"
    },
    {
        "label": "SeeMail",
        "type": "application/vnd.seemail",
        "extension": ".see"
    },
    {
        "label": "Digital Siesmograph Networks – SEED Datafiles",
        "type": "application/vnd.fdsn.seed",
        "extension": ".seed"
    },
    {
        "label": "Secured eMail",
        "type": "application/vnd.sema",
        "extension": ".sema"
    },
    {
        "label": "Secured eMail",
        "type": "application/vnd.semd",
        "extension": ".semd"
    },
    {
        "label": "Secured eMail",
        "type": "application/vnd.semf",
        "extension": ".semf"
    },
    {
        "label": "Java Serialized Object",
        "type": "application/java-serialized-object",
        "extension": ".ser"
    },
    {
        "label": "Secure Electronic Transaction – Payment",
        "type": "application/set-payment-initiation",
        "extension": ".setpay"
    },
    {
        "label": "Secure Electronic Transaction – Registration",
        "type": "application/set-registration-initiation",
        "extension": ".setreg"
    },
    {
        "label": "Hydrostatix Master Suite",
        "type": "application/vnd.hydrostatix.sof-data",
        "extension": ".sfd-hdstx"
    },
    {
        "label": "TIBCO Spotfire",
        "type": "application/vnd.spotfire.sfs",
        "extension": ".sfs"
    },
    {
        "label": "StarOffice – Writer (Global)",
        "type": "application/vnd.stardivision.writer-global",
        "extension": ".sgl"
    },
    {
        "label": "Standard Generalized Markup Language (SGML)",
        "type": "text/sgml",
        "extension": ".sgml"
    },
    {
        "label": "Bourne Shell Script",
        "type": "application/x-sh",
        "extension": ".sh"
    },
    {
        "label": "Shell Archive",
        "type": "application/x-shar",
        "extension": ".shar"
    },
    {
        "label": "S Hexdump Format",
        "type": "application/shf+xml",
        "extension": ".shf"
    },
    {
        "label": "Symbian Install Package",
        "type": "application/vnd.symbian.install",
        "extension": ".sis"
    },
    {
        "label": "Stuffit Archive",
        "type": "application/x-stuffit",
        "extension": ".sit"
    },
    {
        "label": "Stuffit Archive",
        "type": "application/x-stuffitx",
        "extension": ".sitx"
    },
    {
        "label": "SSEYO Koan Play File",
        "type": "application/vnd.koan",
        "extension": ".skp"
    },
    {
        "label": "SimpleAnimeLite Player",
        "type": "application/vnd.epson.salt",
        "extension": ".slt"
    },
    {
        "label": "StepMania",
        "type": "application/vnd.stepmania.stepchart",
        "extension": ".sm"
    },
    {
        "label": "StarOffice – Math",
        "type": "application/vnd.stardivision.math",
        "extension": ".smf"
    },
    {
        "label": "Synchronized Multimedia Integration Language",
        "type": "application/smil+xml",
        "extension": ".smi"
    },
    {
        "label": "Server Normal Format",
        "type": "application/x-font-snf",
        "extension": ".snf"
    },
    {
        "label": "SMAF Phrase",
        "type": "application/vnd.yamaha.smaf-phrase",
        "extension": ".spf"
    },
    {
        "label": "FutureSplash Animator",
        "type": "application/x-futuresplash",
        "extension": ".spl"
    },
    {
        "label": "In3D – 3DML",
        "type": "text/vnd.in3d.spot",
        "extension": ".spot"
    },
    {
        "label": "Server-Based Certificate Validation Protocol – Validation Policies – Response",
        "type": "application/scvp-vp-response",
        "extension": ".spp"
    },
    {
        "label": "Server-Based Certificate Validation Protocol – Validation Policies – Request",
        "type": "application/scvp-vp-request",
        "extension": ".spq"
    },
    {
        "label": "WAIS Source",
        "type": "application/x-wais-source",
        "extension": ".src"
    },
    {
        "label": "Search/Retrieve via URL Response Format",
        "type": "application/sru+xml",
        "extension": ".sru"
    },
    {
        "label": "SPARQL – Results",
        "type": "application/sparql-results+xml",
        "extension": ".srx"
    },
    {
        "label": "Kodak Storyshare",
        "type": "application/vnd.kodak-descriptor",
        "extension": ".sse"
    },
    {
        "label": "QUASS Stream Player",
        "type": "application/vnd.epson.ssf",
        "extension": ".ssf"
    },
    {
        "label": "Speech Synthesis Markup Language",
        "type": "application/ssml+xml",
        "extension": ".ssml"
    },
    {
        "label": "SailingTracker",
        "type": "application/vnd.sailingtracker.track",
        "extension": ".st"
    },
    {
        "label": "OpenOffice – Calc Template (Spreadsheet)",
        "type": "application/vnd.sun.xml.calc.template",
        "extension": ".stc"
    },
    {
        "label": "OpenOffice – Draw Template (Graphics)",
        "type": "application/vnd.sun.xml.draw.template",
        "extension": ".std"
    },
    {
        "label": "Worldtalk",
        "type": "application/vnd.wt.stf",
        "extension": ".stf"
    },
    {
        "label": "OpenOffice – Impress Template (Presentation)",
        "type": "application/vnd.sun.xml.impress.template",
        "extension": ".sti"
    },
    {
        "label": "Hyperstudio",
        "type": "application/hyperstudio",
        "extension": ".stk"
    },
    {
        "label": "Proprietary P&amp;G Standard Reporting System",
        "type": "application/vnd.pg.format",
        "extension": ".str"
    },
    {
        "label": "OpenOffice – Writer Template (Text – HTML)",
        "type": "application/vnd.sun.xml.writer.template",
        "extension": ".stw"
    },
    {
        "label": "Close Captioning – Subtitle",
        "type": "image/vnd.dvb.subtitle",
        "extension": ".sub"
    },
    {
        "label": "ScheduleUs",
        "type": "application/vnd.sus-calendar",
        "extension": ".sus"
    },
    {
        "label": "System V Release 4 CPIO Archive",
        "type": "application/x-sv4cpio",
        "extension": ".sv4cpio"
    },
    {
        "label": "System V Release 4 CPIO Checksum Data",
        "type": "application/x-sv4crc",
        "extension": ".sv4crc"
    },
    {
        "label": "Digital Video Broadcasting",
        "type": "application/vnd.dvb.service",
        "extension": ".svc"
    },
    {
        "label": "SourceView Document",
        "type": "application/vnd.svd",
        "extension": ".svd"
    },
    {
        "label": "Scalable Vector Graphics (SVG)",
        "type": "image/svg+xml",
        "extension": ".svg"
    },
    {
        "label": "OpenOffice – Calc (Spreadsheet)",
        "type": "application/vnd.sun.xml.calc",
        "extension": ".sxc"
    },
    {
        "label": "OpenOffice – Draw (Graphics)",
        "type": "application/vnd.sun.xml.draw",
        "extension": ".sxd"
    },
    {
        "label": "OpenOffice – Writer (Text – HTML)",
        "type": "application/vnd.sun.xml.writer.global",
        "extension": ".sxg"
    },
    {
        "label": "OpenOffice – Impress (Presentation)",
        "type": "application/vnd.sun.xml.impress",
        "extension": ".sxi"
    },
    {
        "label": "OpenOffice – Math (Formula)",
        "type": "application/vnd.sun.xml.math",
        "extension": ".sxm"
    },
    {
        "label": "OpenOffice – Writer (Text – HTML)",
        "type": "application/vnd.sun.xml.writer",
        "extension": ".sxw"
    },
    {
        "label": "troff",
        "type": "text/troff",
        "extension": ".t"
    },
    {
        "label": "Tao Intent",
        "type": "application/vnd.tao.intent-module-archive",
        "extension": ".tao"
    },
    {
        "label": "Tar File (Tape Archive)",
        "type": "application/x-tar",
        "extension": ".tar"
    },
    {
        "label": "Tcl Script",
        "type": "application/x-tcl",
        "extension": ".tcl"
    },
    {
        "label": "SMART Technologies Apps",
        "type": "application/vnd.smart.teacher",
        "extension": ".teacher"
    },
    {
        "label": "Text Encoding and Interchange",
        "type": "application/tei+xml",
        "extension": ".tei"
    },
    {
        "label": "TeX",
        "type": "application/x-tex",
        "extension": ".tex"
    },
    {
        "label": "GNU Texinfo Document",
        "type": "application/x-texinfo",
        "extension": ".texinfo"
    },
    {
        "label": "Sharing Transaction Fraud Data",
        "type": "application/thraud+xml",
        "extension": ".tfi"
    },
    {
        "label": "TeX Font Metric",
        "type": "application/x-tex-tfm",
        "extension": ".tfm"
    },
    {
        "label": "Tagged Image File Format",
        "type": "image/tiff",
        "extension": ".tiff"
    },
    {
        "label": "BitTorrent",
        "type": "application/x-bittorrent",
        "extension": ".torrent"
    },
    {
        "label": "Groove – Tool Template",
        "type": "application/vnd.groove-tool-template",
        "extension": ".tpl"
    },
    {
        "label": "TRI Systems Config",
        "type": "application/vnd.trid.tpt",
        "extension": ".tpt"
    },
    {
        "label": "True BASIC",
        "type": "application/vnd.trueapp",
        "extension": ".tra"
    },
    {
        "label": "Time Stamped Data Envelope",
        "type": "application/timestamped-data",
        "extension": ".tsd"
    },
    {
        "label": "Tab Seperated Values",
        "type": "text/tab-separated-values",
        "extension": ".tsv"
    },
    {
        "label": "TrueType Font",
        "type": "application/x-font-ttf",
        "extension": ".ttf"
    },
    {
        "label": "Turtle (Terse RDF Triple Language)",
        "type": "text/turtle",
        "extension": ".ttl"
    },
    {
        "label": "SimTech MindMapper",
        "type": "application/vnd.simtech-mindmapper",
        "extension": ".twd"
    },
    {
        "label": "Genomatix Tuxedo Framework",
        "type": "application/vnd.genomatix.tuxedo",
        "extension": ".txd"
    },
    {
        "label": "Text File",
        "type": "text/plain",
        "extension": ".txt"
    },
    {
        "label": "Universal Forms Description Language",
        "type": "application/vnd.ufdl",
        "extension": ".ufd"
    },
    {
        "label": "UMAJIN",
        "type": "application/vnd.umajin",
        "extension": ".umj"
    },
    {
        "label": "Unity 3d",
        "type": "application/vnd.unity",
        "extension": ".unityweb"
    },
    {
        "label": "Unique Object Markup Language",
        "type": "application/vnd.uoml+xml",
        "extension": ".uoml"
    },
    {
        "label": "URI Resolution Services",
        "type": "text/uri-list",
        "extension": ".uri"
    },
    {
        "label": "Ustar (Uniform Standard Tape Archive)",
        "type": "application/x-ustar",
        "extension": ".ustar"
    },
    {
        "label": "User Interface Quartz – Theme (Symbian)",
        "type": "application/vnd.uiq.theme",
        "extension": ".utz"
    },
    {
        "label": "UUEncode",
        "type": "text/x-uuencode",
        "extension": ".uu"
    },
    {
        "label": "DECE Audio",
        "type": "audio/vnd.dece.audio",
        "extension": ".uva"
    },
    {
        "label": "DECE High Definition Video",
        "type": "video/vnd.dece.hd",
        "extension": ".uvh"
    },
    {
        "label": "DECE Graphic",
        "type": "image/vnd.dece.graphic",
        "extension": ".uvi"
    },
    {
        "label": "DECE Mobile Video",
        "type": "video/vnd.dece.mobile",
        "extension": ".uvm"
    },
    {
        "label": "DECE PD Video",
        "type": "video/vnd.dece.pd",
        "extension": ".uvp"
    },
    {
        "label": "DECE SD Video",
        "type": "video/vnd.dece.sd",
        "extension": ".uvs"
    },
    {
        "label": "DECE MP4",
        "type": "video/vnd.uvvu.mp4",
        "extension": ".uvu"
    },
    {
        "label": "DECE Video",
        "type": "video/vnd.dece.video",
        "extension": ".uvv"
    },
    {
        "label": "Video CD",
        "type": "application/x-cdlink",
        "extension": ".vcd"
    },
    {
        "label": "vCard",
        "type": "text/x-vcard",
        "extension": ".vcf"
    },
    {
        "label": "Groove – Vcard",
        "type": "application/vnd.groove-vcard",
        "extension": ".vcg"
    },
    {
        "label": "vCalendar",
        "type": "text/x-vcalendar",
        "extension": ".vcs"
    },
    {
        "label": "VirtualCatalog",
        "type": "application/vnd.vcx",
        "extension": ".vcx"
    },
    {
        "label": "Visionary",
        "type": "application/vnd.visionary",
        "extension": ".vis"
    },
    {
        "label": "Vivo",
        "type": "video/vnd.vivo",
        "extension": ".viv"
    },
    {
        "label": "Viewport+",
        "type": "application/vnd.vsf",
        "extension": ".vsf"
    },
    {
        "label": "Virtue VTU",
        "type": "model/vnd.vtu",
        "extension": ".vtu"
    },
    {
        "label": "VoiceXML",
        "type": "application/voicexml+xml",
        "extension": ".vxml"
    },
    {
        "label": "Doom Video Game",
        "type": "application/x-doom",
        "extension": ".wad"
    },
    {
        "label": "Waveform Audio File Format (WAV)",
        "type": "audio/x-wav",
        "extension": ".wav"
    },
    {
        "label": "WAP Bitamp (WBMP)",
        "type": "image/vnd.wap.wbmp",
        "extension": ".wbmp"
    },
    {
        "label": "Critical Tools – PERT Chart EXPERT",
        "type": "application/vnd.criticaltools.wbs+xml",
        "extension": ".wbs"
    },
    {
        "label": "WAP Binary XML (WBXML)",
        "type": "application/vnd.wap.wbxml",
        "extension": ".wbxml"
    },
    {
        "label": "Open Web Media Project – Audio",
        "type": "audio/webm",
        "extension": ".weba"
    },
    {
        "label": "Open Web Media Project – Video",
        "type": "video/webm",
        "extension": ".webm"
    },
    {
        "label": "WebP Image",
        "type": "image/webp",
        "extension": ".webp"
    },
    {
        "label": "Qualcomm’s Plaza Mobile Internet",
        "type": "application/vnd.pmi.widget",
        "extension": ".wg"
    },
    {
        "label": "Widget Packaging and XML Configuration",
        "type": "application/widget",
        "extension": ".wgt"
    },
    {
        "label": "Wireless Markup Language (WML)",
        "type": "text/vnd.wap.wml",
        "extension": ".wml"
    },
    {
        "label": "Compiled Wireless Markup Language (WMLC)",
        "type": "application/vnd.wap.wmlc",
        "extension": ".wmlc"
    },
    {
        "label": "Wireless Markup Language Script (WMLScript)",
        "type": "text/vnd.wap.wmlscript",
        "extension": ".wmls"
    },
    {
        "label": "WMLScript",
        "type": "application/vnd.wap.wmlscriptc",
        "extension": ".wmlsc"
    },
    {
        "label": "Web Open Font Format",
        "type": "application/x-font-woff",
        "extension": ".woff"
    },
    {
        "label": "Wordperfect",
        "type": "application/vnd.wordperfect",
        "extension": ".wpd"
    },
    {
        "label": "SundaHus WQ",
        "type": "application/vnd.wqd",
        "extension": ".wqd"
    },
    {
        "label": "Virtual Reality Modeling Language",
        "type": "model/vrml",
        "extension": ".wrl"
    },
    {
        "label": "WSDL – Web Services Description Language",
        "type": "application/wsdl+xml",
        "extension": ".wsdl"
    },
    {
        "label": "Web Services Policy",
        "type": "application/wspolicy+xml",
        "extension": ".wspolicy"
    },
    {
        "label": "WebTurbo",
        "type": "application/vnd.webturbo",
        "extension": ".wtb"
    },
    {
        "label": "CorelXARA",
        "type": "application/vnd.xara",
        "extension": ".xar"
    },
    {
        "label": "Fujitsu – Xerox DocuWorks Binder",
        "type": "application/vnd.fujixerox.docuworks.binder",
        "extension": ".xbd"
    },
    {
        "label": "X BitMap",
        "type": "image/x-xbitmap",
        "extension": ".xbm"
    },
    {
        "label": "XML Configuration Access Protocol – XCAP Diff",
        "type": "application/xcap-diff+xml",
        "extension": ".xdf"
    },
    {
        "label": "SyncML – Device Management",
        "type": "application/vnd.syncml.dm+xml",
        "extension": ".xdm"
    },
    {
        "label": "Data Structure for the Security Suitability of Cryptographic Algorithms",
        "type": "application/dssc+xml",
        "extension": ".xdssc"
    },
    {
        "label": "Fujitsu – Xerox DocuWorks",
        "type": "application/vnd.fujixerox.docuworks",
        "extension": ".xdw"
    },
    {
        "label": "XML Encryption Syntax and Processing",
        "type": "application/xenc+xml",
        "extension": ".xenc"
    },
    {
        "label": "XML Patch Framework",
        "type": "application/patch-ops-error+xml",
        "extension": ".xer"
    },
    {
        "label": "Extensible Forms Description Language",
        "type": "application/vnd.xfdl",
        "extension": ".xfdl"
    },
    {
        "label": "XHTML – The Extensible HyperText Markup Language",
        "type": "application/xhtml+xml",
        "extension": ".xhtml"
    },
    {
        "label": "eXtended Image File Format (XIFF)",
        "type": "image/vnd.xiff",
        "extension": ".xif"
    },
    {
        "label": "Microsoft Excel – Add-In File",
        "type": "application/vnd.ms-excel.addin.macroenabled.12",
        "extension": ".xlam"
    },
    {
        "label": "Microsoft Excel",
        "type": "application/vnd.ms-excel",
        "extension": ".xls"
    },
    {
        "label": "Microsoft Excel – Binary Workbook",
        "type": "application/vnd.ms-excel.sheet.binary.macroenabled.12",
        "extension": ".xlsb"
    },
    {
        "label": "Microsoft Excel – Macro-Enabled Workbook",
        "type": "application/vnd.ms-excel.sheet.macroenabled.12",
        "extension": ".xlsm"
    },
    {
        "label": "Microsoft Excel – Macro-Enabled Template File",
        "type": "application/vnd.ms-excel.template.macroenabled.12",
        "extension": ".xltm"
    },
    {
        "label": "XML – Extensible Markup Language",
        "type": "application/xml",
        "extension": ".xml"
    },
    {
        "label": "Sugar Linux Application Bundle",
        "type": "application/vnd.olpc-sugar",
        "extension": ".xo"
    },
    {
        "label": "XML-Binary Optimized Packaging",
        "type": "application/xop+xml",
        "extension": ".xop"
    },
    {
        "label": "XPInstall – Mozilla",
        "type": "application/x-xpinstall",
        "extension": ".xpi"
    },
    {
        "label": "X PixMap",
        "type": "image/x-xpixmap",
        "extension": ".xpm"
    },
    {
        "label": "Express by Infoseek",
        "type": "application/vnd.is-xpr",
        "extension": ".xpr"
    },
    {
        "label": "Intercon FormNet",
        "type": "application/vnd.intercon.formnet",
        "extension": ".xpw"
    },
    {
        "label": "XML Transformations",
        "type": "application/xslt+xml",
        "extension": ".xslt"
    },
    {
        "label": "SyncML",
        "type": "application/vnd.syncml+xml",
        "extension": ".xsm"
    },
    {
        "label": "XSPF – XML Shareable Playlist Format",
        "type": "application/xspf+xml",
        "extension": ".xspf"
    },
    {
        "label": "XUL – XML User Interface Language",
        "type": "application/vnd.mozilla.xul+xml",
        "extension": ".xul"
    },
    {
        "label": "X Window Dump",
        "type": "image/x-xwindowdump",
        "extension": ".xwd"
    },
    {
        "label": "XYZ File Format",
        "type": "chemical/x-xyz",
        "extension": ".xyz"
    },
    {
        "label": "YAML Ain’t Markup Language / Yet Another Markup Language",
        "type": "text/yaml",
        "extension": ".yaml"
    },
    {
        "label": "YANG Data Modeling Language",
        "type": "application/yang",
        "extension": ".yang"
    },
    {
        "label": "YIN (YANG – XML)",
        "type": "application/yin+xml",
        "extension": ".yin"
    },
    {
        "label": "Zzazz Deck",
        "type": "application/vnd.zzazz.deck+xml",
        "extension": ".zaz"
    },
    {
        "label": "Zip Archive",
        "type": "application/zip",
        "extension": ".zip"
    },
    {
        "label": "Z.U.L. Geometry",
        "type": "application/vnd.zul",
        "extension": ".zir"
    },
    {
        "label": "ZVUE Media Manager",
        "type": "application/vnd.handheld-entertainment+xml",
        "extension": ".zmm"
    }
]