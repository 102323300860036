import React, { Component } from 'react';
import { connect } from 'react-redux'

import MaterialTable from 'material-table';
import { Grid, Box } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { withSnackbar } from 'notistack';
import {
    TableIcons,
    UploadIcon,
    AffidavitIcon,
    InvoiceIcon,
    BellIcon
} from './TableIcons';
import {AccessTime, CheckCircleOutline, InfoOutlined} from '@material-ui/icons/';
import {theme_color} from '../tempfile'


import { getDocumentsByURL, openUploadDialog, openAlerts } from '../redux/actions'
const minWidth1 = 200;
const minWidth2 = 50;
const minWidth3 = 150;

class Jobs extends Component {



    constructor(props) {
      super(props);

      this.state = {
        columns: [
            { title: 'Job ID', field: 'id' },
            { title: 'Priority', field: 'priority' },
            { title: 'Defendant', field: 'defendant' },
            { title: 'Received Date', field: 'receivedDate' },
            { title: 'Serve By Date', field: 'serveByDate' },
            { title: 'Latest Service', field: 'servedDate' },
            {
                title: 'Status',
                field: 'status',
                "headerStyle": {width: minWidth1}, "cellStyle": {width: minWidth1},
                render: job => <Box display="flex" alignItems="center">
                                  {job.status === 'Completed' && job.attempts.length > 0 ?
                                    <CheckCircleOutline style={{ color: '#7EE0D4' }} />
                                      :
                                      job.status === 'Attempted' && job.attempts.length > 0 ?
                                        <InfoOutlined style={{ color: '#FFD700' }}/>
                                        :
                                        <AccessTime style={{ color: '#606060' }} />
                                  }
                                  &nbsp;&nbsp;
                                  {job.status == "Completed" && job.attempts.length > 0 ?
                                    "Completed"
                                    :
                                    job.status === 'Attempted' && job.attempts.length > 0 ?
                                    "Attempted"
                                    :
                                    "Pending"
                                  }
                              </Box>
            }
        ],
        columns2: [
          { title: 'Attempt #', field: 'attemptNumber',
          "headerStyle": {width: minWidth2}, "cellStyle": {width: minWidth2}},
          { title: 'Date', field: 'date',
          "headerStyle": {width: minWidth3}, "cellStyle": {width: minWidth3}},
          { title: 'Defendant Address', field: 'defendantAddress',
          "headerStyle": {width: minWidth1}, "cellStyle": {width: minWidth1}},
          { title: 'Type', field: 'type',
          "headerStyle": {width: minWidth3}, "cellStyle": {width: minWidth3}},
          //{ title: 'Caption', field: 'caption' },
          { title: 'Comments', field: 'comments',
          "headerStyle": {width: minWidth1}, "cellStyle": {width: minWidth1}},
        ]
      };
    }
    render(){
      return (
        <Box bgcolor={blueGrey[50]}>
            <Grid item xs={11}>
                <Box p={0.5}>
                    <MaterialTable
                        columns={this.state.columns}
                        data={this.props.jobs}
                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: rowData => {
                                    return (
                                      <Box bgcolor={blueGrey[50]}>
                                          <Grid item xs={11}>
                                              <Box p={0.5}>
                                                  <MaterialTable
                                                      columns={this.state.columns2}
                                                      data={rowData.attempts}
                                                      options={{
                                                          search: false,
                                                          toolbar: false,
                                                          padding: 'dense',
                                                          emptyRowsWhenPaging: false,
                                                          paging: false,
                                                          headerStyle: {
                                                            padding: '0.5rem',
                                                            backgroundColor: theme_color,
                                                            color: 'white',
                                                          },
                                                      }}
                                                      icons={TableIcons}
                                                  />
                                              </Box>
                                          </Grid>
                                      </Box>
                                    );
                                }
                            }
                        ]}
                        onRowClick={(event, rowData, togglePanel) =>
                            togglePanel()
                        }
                        options={{
                            search: false,
                            toolbar: false,
                            padding: 'dense',
                            emptyRowsWhenPaging: false,
                            paging: false,
                            headerStyle: {
                              padding: '0.5rem',
                              backgroundColor: theme_color,
                              color: 'white',
                            },
                            actionsColumnIndex: -1
                        }}
                        icons={TableIcons}
                        actions={[
                            {
                                icon: () => <UploadIcon />,
                                tooltip: 'Upload Document',
                                onClick: (event, job) =>
                                    this.props.openUploadDialog({
                                        refId: job.id,
                                        type: 'job'
                                    })
                            },
                            job => ({
                                icon: () => <InvoiceIcon />,
                                tooltip: 'Download Invoice',
                                hidden: !job.invoiceUrl,
                                onClick: (event, job) => {
                                    this.props.enqueueSnackbar('Downloading Invoice...',{variant: 'info'});
                                    this.props.getDocumentsByURL([job.invoiceUrl]).then((res) => {
                                          if(!res){
                                            this.props.enqueueSnackbar("Could not download invoice", {variant: 'error'});
                                          }else{
                                            this.props.enqueueSnackbar("Invoice downloaded", {variant: 'success'});
                                          }
                                        }
                                    );
                                }
                            }),
                            job => ({
                                icon: () => <AffidavitIcon />,
                                tooltip: 'Download Affidavit',
                                hidden: !job.affidavitUrl,
                                onClick: (event, job) => {
                                    this.props.enqueueSnackbar('Downloading Affidavit...', {variant: 'info'});
                                    this.props.getDocumentsByURL([job.affidavitUrl]).then((res) => {
                                        if(!res){
                                            this.props.enqueueSnackbar("Could not download affidavit", {variant: 'error'});
                                        }else{
                                          this.props.enqueueSnackbar("Affidavit downloaded", {variant: 'success'});
                                        }
                                    });
                                }
                            }),
                            job => ({
                                icon: () => {
                                    return <BellIcon />;
                                },
                                tooltip: 'Alert',
                                onClick: (e, j) => {
                                    this.props.openAlerts(e.currentTarget, j.alerts);
                                },
                                hidden: job.alerts.every(a => a.dismissed)
                            })
                        ]}
                    />
                </Box>
            </Grid>
        </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statements: state.statements,
    apiToken: state.apiToken
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  { getDocumentsByURL, openUploadDialog, openAlerts }
)(Jobs))
