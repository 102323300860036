import React from 'react';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Gavel from '@material-ui/icons/Gavel';
import Receipt from '@material-ui/icons/Receipt';
import Info from '@material-ui/icons/Info'

import styled from 'styled-components';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Download } from './download.svg';
import * as Affidavit from './affidavit-icon.png';
import * as Invoice from './invoice-icon.png';
import {iconColor} from '../tempfile'

export const TableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const SvgIcon = styled.svg`
    width: 1.6rem;
    height: 1.6rem;
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

const PngIcon = styled.img`
    width: 1.6rem;
    color:
`;

export const UploadIcon = () => (
    <SvgIcon viewBox="0 0 50 50">
        <Upload />
    </SvgIcon>
);

export const DownloadIcon = () => (
    <SvgIcon viewBox="0 0 50 50">
        <Download />
    </SvgIcon>
);

export const AffidavitIcon = styled(Gavel)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const InvoiceIcon = styled(Receipt)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const BellIcon = styled(NotificationsActive)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
