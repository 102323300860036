import {
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
	CLIENT_LOADED,
	LOGOUT,
  CASES_RECEIVED,
  ALERTS_RECEIVED,
  STATS_RECEIVED,
  DOCS_RECEIVED,
  ADD_DOCS,
  STATEMENTS_RECEIVED,
  CLOSE_MODALS,
  OPEN_ALERT,
  OPEN_UPLOAD_DIALOG
} from './types'

import { flattenObject } from './util';
import { MIME_TYPES } from './util';
import { saveFile } from './util';
import moment from "moment";

import axios from 'axios';
import {Legalex_API_Key, ServerPath} from '../tempfile'

const STATUS_CODES = {
    ATTEMPTED: 2,
    COMPLETED: 4,
    PENDING: 8
};
const STATUSES = {
    [STATUS_CODES.COMPLETED]: 'Completed',
    [STATUS_CODES.ATTEMPTED]: 'Attempted',
    [STATUS_CODES.PENDING]: 'Pending'
};

const priorities = {
    1: 'Standard',
    2: 'Rush',
    3: 'Same Day Rush',
    4: 'On Hold'
};

export const login = ({ username, password, clientId }) => {
  return (dispatch, getState) => {

    const requestBody = {
        API_key: Legalex_API_Key,
        user: username,
        password: password
    };

    if (clientId) {
        requestBody.client_id = clientId;
    }

		console.log(requestBody)
    return axios.post(ServerPath + `/api/login`, requestBody).then(response => {
			console.log(response)
        if (!response.data.success) {
          dispatch({ type: LOGIN_FAILED });
          return Promise.resolve(false);
        }else{
          dispatch({ type: LOGIN_SUCCEEDED, payload: response.data.token });
					window.localStorage.setItem('username', JSON.stringify(username));
					window.localStorage.setItem('password', JSON.stringify(password));
          return Promise.resolve(true);
        }
    });
  }
};

export const logout = () => {
  return (dispatch, getState) => {

    const {apiToken} = getState();

    return axios.post(ServerPath + `/api/logout`, { token: apiToken }).then(response => {
      if (!response.data.success) {
        dispatch({ type: LOGOUT });
				console.log("log out failed")
        return Promise.resolve(false);
      }else{
        dispatch({ type: LOGOUT });
				window.localStorage.removeItem('username');
				window.localStorage.removeItem('password');
				console.log("logged out")
        return Promise.resolve(true);
      }
    });
  }
};

export const getAlerts = () => {
  return (dispatch, getState) => {

    const {apiToken} = getState();

    return axios.post(ServerPath + '/api/getAlerts', {token: apiToken}).then(response => {

      console.log('getAlerts[resp]: ', response.data)

      const alerts = response.data.alerts.map(a => {
          return {
              id: a.alert_id,
              clientId: a.client_id,
              message: a.message,
              type: a.type,
              refId: a.ref_id,
              dismissed: false
          };
      });

      dispatch({type: ALERTS_RECEIVED, payload: alerts});
      return Promise.resolve(response.data.success);
    });
  }
};

export const completeAlert = (alert) => {
  return (dispatch, getState) => {
    const {apiToken} = getState();

    const data = {
        token: apiToken,
        alert_id: alert.id
    }

    return axios.post(ServerPath + '/api/completeAlert', data).then(response => {
      //dispatch(ALERT_COMPLETED, response.data.success);
      return Promise.resolve(response.data.success);
    })
  }
};

export const getClient = () => {
  return (dispatch, getState) => {
    const {apiToken} = getState();

    const data = {
        token: apiToken
    }

    return axios.post(ServerPath + '/api/getClient', data).then(response => {
      dispatch({type: CLIENT_LOADED, payload: response.data.client});
      return Promise.resolve(response.data.success);

    });
  }
}

export const getCases = (searchParams) => {
  return (dispatch, getState) => {
    const {apiToken} = getState();

    const data = {
        token: apiToken,
        num: 20,
        params: {
            startDate: searchParams && searchParams.startDate ? searchParams.startDate : null,
            endDate: searchParams && searchParams.startDate ? searchParams.endDate : null
        }
    }

    console.log('getCases[data]: ', data)
    return axios.post(ServerPath + '/api/getCases', data).then(response => {
      console.log('getCases[resp]: ', response)
      if (response.data.success) {
        const cases = filterResponse(mapCases(response.data), searchParams);
        dispatch({type: CASES_RECEIVED, payload: cases});
        return Promise.resolve(true);
      }else{
        return Promise.resolve(false);
      }
    });
  }
}

export const getStatements = () => {
  return (dispatch, getState) => {
    const {apiToken} = getState();
    const data = {
        token: apiToken
    }
    return axios.post(ServerPath + '/api/getStatements', data).then(response => {
      if(response.data.success){
        const statements = response.data.statements.map(s => {return{
            id: s.statement_id,
            dateIssued: s.date_issued,
            statementNo: s.statement_no,
            url: s.url,
            clientId: s.client_id
        }});
        console.log('statements', statements)
        dispatch({type: STATEMENTS_RECEIVED, payload: statements});
        return Promise.resolve(true);
      }else{
        return Promise.resolve(false);
      }
    });
  }
};

export const addDocuments = (files, refId, type) => {

  return (dispatch, getState) => {
    const {apiToken} = getState();

    console.log("files")
    console.log(files.files)

    return Promise.all(files.files.map(f => toBase64(f.rawFile))).then(b64Encoded => {
        const docs = {
            doc_list: [],
            doc_bytes: [],
            doc_types: [],
            doc_folders: []
        };
        files.files.forEach((f, i) => {
            const extension = MIME_TYPES.find(t => t.type === f.type).extension;
            docs.doc_list.push(f.name + extension);
            docs.doc_bytes.push(b64Encoded[i]);
            docs.doc_types.push(f.type);
            docs.doc_folders.push(f.destinationFolder);
        });

        const data = {
            token: apiToken,
            ref_id: refId,
            type: type,
            ...docs
        }

        return axios.post(ServerPath + '/api/addDocuments', data).then(response => {
          dispatch({type: ADD_DOCS, payload: response.data.success});
          return Promise.resolve(response.data.success);
        });
    });
  }
};

export const getDocumentsByURL = (urls) => {

  return (dispatch, getState) => {
    const {apiToken} = getState();

    const data = {
        token: apiToken,
        urls: urls
    }

    return axios.post(ServerPath + '/api/getDocumentsByURL', data).then(response => {
        if (response.data.success) {
            const extractFilename = url => url.split('/').pop();
            const d = response.data;

            const files = urls.map((url, i) => {
                return {
                    filename: extractFilename(url),
                    bytes: d.bytes[i],
                    type: (d.types && d.types[i]) || 'application/pdf'
                };
            });

            files.forEach(f => {
                saveFile(
                    `data:${f.type};base64,${f.bytes}`,
                    f.filename,
                    f.type
                );
            });

            dispatch({type: DOCS_RECEIVED, payload: files});
            return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
    });
  }
};

export const jobsCompletedMetrics = () => {

  return (dispatch, getState) => {
    const {apiToken} = getState();

    const data = {
        token: apiToken
    }


    return axios.post(ServerPath + '/api/jobsCompletedMetrics', data).then(response => {
      console.log('jobMetrics[resp]: ', response.data)
        if (response.data.success) {
            const stats = Object.keys(response.data.stats).sort().map(k => {
                return {
                    x: k,
                    y: response.data.stats[k]
                };
            });
            console.log('stats', stats);
            dispatch({type: STATS_RECEIVED, payload: stats});
            return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
    });
  }
};

export const openUploadDialog = (val) => {
  return (dispatch, getState) => {
    dispatch({type: OPEN_UPLOAD_DIALOG, payload: val});
  }
}

export const closeModals = () => {
  return (dispatch, getState) => {
    dispatch({type: CLOSE_MODALS});
  }
}

export const openAlerts = (component, val) => {
  return (dispatch, getState) => {
    dispatch({type: OPEN_ALERT, payload: {alerts: val, component: component}});
  }
}


/** HELPERS */
function filterResponse(data, params) {
    console.log('filter: ', data)
    console.log('params: ', params)

    const filterByStatus = (c, status) => {

        const isCompleted = c => c.status.completed === c.status.total;
        const isPending = c =>
            c.jobs.some(j => j.status === STATUSES[STATUS_CODES.PENDING]);
        const isProcessing = c => !isCompleted(c) && !isPending(c);

        if (status === -1) {
            return true;
        } else if (status === STATUS_CODES.COMPLETED) {
            return isCompleted(c);
        } else if (status === STATUS_CODES.PENDING) {
            return isPending(c);
        } else {
            return isProcessing(c);
        }
    };

    const filterByJobCaseInfo = (c, query) => {
        if (query === '') {
            return true;
        }
        const keywords = query.toLowerCase().split(',');
        const values = Object.values(flattenObject(c));
        return values.some(v =>
            keywords.some(
                k =>
                    v &&
                    v
                        .toString()
                        .toLowerCase()
                        .includes(k.trim())
            )
        );
    };

    return data
        .filter(c => {return params && params.status ? filterByStatus(c, params.status) : c})
        .filter(c => {return params && params.jobCaseInfo ? filterByJobCaseInfo(c, params.jobCaseInfo) : c});
}

function mapCases(data) {
    if (!data || !data.cases || !Array.isArray(data.cases)) {
        throw new Error('Malformed getCases response: missing cases array');
    }

    const formatDate = dateStr => moment(dateStr).format('MMMM Do YYYY, h:mm:ss a') === "Invalid date" ? "" : moment(dateStr).format('MMMM Do YYYY, h:mm:ss a')
    const cleanUp = str => {
        const placeholderValues = ['None', 'Placeholder', '-1'];
        return placeholderValues.includes(str) ? '-' : str;
    };

    const mapJobStatus = statusCode => {
        return STATUSES[statusCode] || 'Processing';
    };

    const mapJobPriority = priority => {
        return priorities[priority] || priorities[1];
    };

    const calculateCaseStatus = jobs => {
        const statuses = jobs.map(j => j.status);
        return {
            completed: statuses.filter(s => s === 4).length,
            total: statuses.length
        };
    };

    console.log(data.cases)

    return data.cases.map(c => {
        return {
            id: c.case_id,
            attorney: cleanUp(c.attorney),
            clientRef: cleanUp(c.client_ref),
            plaintiff: cleanUp(c.plaintiff),
            caseNumber: cleanUp(c.case_no),
            leadDefendant: cleanUp(c.lead_defendant),
            court: cleanUp(c.court),
            clientBilling: cleanUp(c.client_billing),
            status: calculateCaseStatus(c.jobs),
            invoiceUrls: c.invoice_urls || [],
            affidavitUrls: c.affidavit_urls || [],
            alerts: [],
            jobs: c.jobs.map(j => {
                return {
                    id: j.job_id,
                    priority: mapJobPriority(j.priority),
                    defendant: cleanUp(j.defendant),
                    receivedDate: formatDate(j.received_date),
                    serveByDate: formatDate(j.serve_by_date),
                    servedDate: formatDate(j.served_date) == "Invalid Date" ? "" : formatDate(j.served_date),
                    status: mapJobStatus(j.status),
                    affidavitUrl: j.affidavit_url,
                    invoiceUrl: j.invoice_url,
                    alerts: [],
                    attempts: j.attempts.map(a => {
                        return {
                            attemptNumber: cleanUp(a.attempt_no),
                            date: formatDate(a.date),
                            defendantAddress: cleanUp(a.defendant_address),
                            comments: cleanUp(a.comments),
                            type: cleanUp(a.type) == 1 ? "Successful" : "Unsuccessful",
                            caption: cleanUp(a.caption),
                        };
                    })
                };
            })
        };
    });
}

const toBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let res = reader.result.split(',')[1];
            resolve(res);
        };
        reader.onerror = error => reject(error);
    });
};
