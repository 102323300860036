import React, { Component } from 'react';
import { connect } from 'react-redux'

import MaterialTable from 'material-table';
import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon
} from './TableIcons';
import Jobs from './Jobs';
import {AccessTime, CheckCircleOutline} from '@material-ui/icons/';
import {Box, LinearProgress} from '@material-ui/core/';
import { withSnackbar } from 'notistack';
import {openAlerts, getDocumentsByURL, openUploadDialog} from '../redux/actions';
import {theme_color} from '../tempfile'
const minWidth1 = 150;
const minWidth2 = 200;

class Cases extends Component {

  constructor(props) {
    super(props);

    const minWidth1 = 200;

    this.state = {
      columns: [
          { title: 'Attorney', field: 'attorney',
          "headerStyle": {width: minWidth1}, "cellStyle": {width: minWidth1}},
          { title: 'Client Ref.', field: 'clientRef' },
          { title: 'Plaintiff', field: 'plaintiff' },
          { title: 'Case No.', field: 'caseNumber' },
          { title: 'Lead Defendant', field: 'leadDefendant' },
          { title: 'Court', field: 'court',
          "headerStyle": {width: minWidth2}, "cellStyle": {width: minWidth2}},
          { title: 'Client Billing', field: 'clientBilling' },
          {
              title: 'Status',
              field: 'status',
              "headerStyle": {minWidth: minWidth1}, "cellStyle": {minWidth: minWidth1},
              render: caseRow => <Box display="flex" alignItems="center">
                                    {caseRow.status.total == 0 ? null :
                                      caseRow.status.completed == caseRow.status.total ?
                                    <CheckCircleOutline style={{ color: '#7EE0D4' }} /> :
                                    <AccessTime style={{ color: '#606060' }} />}
                                    &nbsp;&nbsp;
                                    {`${caseRow.status.completed} / ${caseRow.status.total} Served`}
                                </Box>
          }
      ]
    }
  }

  render(){

    const cases = this.props.cases ? this.props.cases : [];

    return (
        <div>
            {!this.props.cases ?
                <LinearProgress />
             :
                <div>
                    <MaterialTable
                        columns={this.state.columns}
                        data={cases}
                        detailPanel={[
                            {
                                tooltip: 'Show Details',
                                render: rowData => {
                                    return <Jobs jobs={rowData.jobs} />;
                                }
                            }
                        ]}
                        onRowClick={(event, rowData, togglePanel) =>
                            togglePanel()
                        }
                        options={{
                            search: false,
                            toolbar: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                              padding: '0.5rem',
                              backgroundColor: theme_color,
                              color: 'white',
                              position: 'sticky',
                              top: 0,
                              zIndex: 999
                            },
                            maxBodyHeight: this.props.maxHeight ? this.props.maxHeight : '650px',
                            actionsColumnIndex: -1
                        }}
                        icons={TableIcons}
                        actions={[
                            {
                                icon: () => <UploadIcon />,
                                tooltip: 'Upload Document',
                                onClick: (e, _case) =>
                                    this.props.openUploadDialog({
                                        refId: _case.id,
                                        type: 'case'
                                    })
                            },
                            caseRow => ({
                                icon: () => <InvoiceIcon />,
                                tooltip: 'Download Invoices',
                                onClick: (e, _case) => {
                                    this.props.enqueueSnackbar('Downloading Invoices...', {variant: 'info'});
                                    this.props.getDocumentsByURL(_case.invoiceUrls).then(res => {
                                      if(!res){
                                        this.props.enqueueSnackbar("Could not download invoices", {variant: 'error'});
                                      }else{
                                        this.props.enqueueSnackbar("invoices downloaded", {variant: 'success'});
                                      }
                                    });
                                },
                                hidden: caseRow.invoiceUrls.length === 0
                            }),
                            caseRow => ({
                                icon: () => <AffidavitIcon />,
                                tooltip: 'Download Affidavits',
                                onClick: (e, _case) => {
                                    this.props.enqueueSnackbar('Downloading Affidavits...', {variant: 'info'});
                                    this.props.getDocumentsByURL(_case.affidavitUrls).then(res => {
                                      if(!res){
                                        this.props.enqueueSnackbar("Could not download affidavits", {variant: 'error'});
                                      }else{
                                        this.props.enqueueSnackbar("Affidavits downloaded", {variant: 'success'});
                                      }
                                    });
                                },
                                hidden: caseRow.affidavitUrls.length === 0
                            }),
                            caseRow => ({
                                icon: () => {
                                    return <BellIcon />;
                                },
                                tooltip: 'Alert',
                                onClick: (e, c) => {
                                    this.props.openAlerts(e.currentTarget, c.alerts);
                                },
                                hidden: caseRow.alerts.every(a => a.dismissed)
                            })
                        ]}
                    />
                </div>
            }
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cases: state.cases
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {openAlerts, getDocumentsByURL, openUploadDialog}
)(Cases))
