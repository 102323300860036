import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './shared/styles';
import { SnackbarProvider } from 'notistack';
import { Provider, connect } from 'react-redux';
import logo from './logo.svg';
import './App.css';

import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import reducers from './redux/reducer';
import AppRouter from './Routes';

function App() {
      const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

    return (
        <Provider store={store}>
          <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <ThemeProvider theme={theme}>
              <AppRouter />
            </ThemeProvider>
          </SnackbarProvider>
        </Provider>
    );
}

export default App;
