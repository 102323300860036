import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';

export default function() {
    return (
        <Router>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" render={() => <Dashboard />} />
        </Router>
    );
}
