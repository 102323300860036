import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withSnackbar, closeSnackbar } from 'notistack';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import Cases from './Cases';
import UploadDialog from './UploadDialog';
import { subMonths } from 'date-fns';

import { Popover, AppBar, Container, Toolbar, Typography, Grid, Button, Box, TextField, MenuItem, Paper, CircularProgress} from '@material-ui/core/';
import { withRouter } from 'react-router-dom';
import { login, logout, getClient, getCases, getStatements, jobsCompletedMetrics, getDocumentsByURL, getAlerts, closeModals } from '../redux/actions';
import styled from 'styled-components';
import {app_user, theme_color} from '../tempfile'
import * as legalex_logo from '../shared/images/legalex_logo.png';
import * as nh_logo from '../shared/images/nh_logo.png';

const LogoWrapper = styled.img`
    width: ${props => (props.size === 'sm' ? '13rem' : '23rem')};
    padding: 0.5rem 0;
`;

export const Logo = ({ size }) => <LogoWrapper size={size} src={app_user == "nh" ? nh_logo : legalex_logo} />;

const validation = Yup.object({
    startDate: Yup.date('Select start date'),
    endDate: Yup.date('Select end date'),
    jobCaseInfo: Yup.string('Enter Job / Case info'),
    status: Yup.number('Select status')
});

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedStatement: null,
      startDate: subMonths(new Date(), 6),
      endDate: new Date(),
      jobCaseInfo: '',
      status: -1
    }
  }

  componentDidMount(){
    console.log("main will load")
    const username = JSON.parse(window.localStorage.getItem('username')) || null;
    const password = JSON.parse(window.localStorage.getItem('password')) || null;
    this.props.login({username, password}).then((res) => {
      if(!res){
        this.props.history.push('/login');
      }
    });
    const smallWait = setInterval(() => {
      if(this.props.apiToken && this.props.login_success){
				clearInterval(smallWait);
        this.props.getCases().then(()=>{
          this.props.getAlerts()
        })
        this.props.getStatements();
        this.props.getClient();
        this.props.jobsCompletedMetrics().then((res) => {
          if(res)
          console.log('stats: ', this.props.stats)
        });
      }
    }, 100);
  }


  onLogoutClicked = () => {
      this.props.logout().then((res) => {
        this.props.history.push('/login');
      });
  };

  handleSubmit = (values) => {
    this.props.getCases({...this.state, ...values}).then(()=>{
      this.props.getAlerts()
    })
  }

  render(){

    const stats = this.props.stats;

    return (
      <div>
            <AppBar color="inherit" position="static">
                <Toolbar>
                    <Grid justify="space-between" alignItems="center" container>
                        <Logo size="sm" />

                            <Box
                                display="flex"
                                flexDirection="row"
                                p={2}
                                alignItems="stretch"
                            >
                            {this.props.client && (

                                <Box
                                    pr={3}
                                    textAlign="right"
                                    color="text.secondary"
                                >
                                    <Typography variant="h6">
                                        {this.props.client.name}
                                    </Typography>
                                    <Typography>
                                        {this.props.client.company} · {this.props.client.client_id}
                                    </Typography>
                                </Box>
                              )}
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={this.onLogoutClicked}
                                >
                                    Log out
                                </Button>
                            </Box>

                    </Grid>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl">
                <Box mt={5}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                          <Formik
                              render={props =>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Typography variant="h5">Search</Typography>
                                    <form onSubmit={props.handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs>
                                                <DatePicker
                                                    name="startDate"
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label="Start Date"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    fullWidth
                                                    value={this.state.startDate}
                                                    onChange={value => {
                                                        this.setState({startDate: value});
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <DatePicker
                                                    name="endDate"
                                                    autoOk
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label="End Date"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    fullWidth
                                                    minDate={this.state.startDate}
                                                    maxDate={new Date()}
                                                    value={this.state.endDate}
                                                    onChange={value => {
                                                        this.setState({endDate: value});
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item xs>
                                                <TextField
                                                    label="Job / Case Information"
                                                    name="jobCaseInfo"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={props.values.jobCaseInfo}
                                                    onChange={props.handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    name="status"
                                                    select
                                                    variant="outlined"
                                                    fullWidth
                                                    value={props.values.status}
                                                    label="Status"
                                                    onChange={props.handleChange}
                                                    margin="normal"
                                                    inputProps={{
                                                        name: 'status',
                                                        id: 'outlined-status-simple'
                                                    }}
                                                >
                                                    <MenuItem value={-1}>Any</MenuItem>
                                                    <MenuItem value={0}>Processing</MenuItem>
                                                    <MenuItem value={4}>Completed</MenuItem>
                                                    <MenuItem value={8}>Pending</MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Button
                                                    size="large"
                                                    type="submit"
                                                    variant="outlined"
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </MuiPickersUtilsProvider>
                              }
                              initialValues={this.state.searchParams}
                              validationSchema={validation}
                              onSubmit={this.handleSubmit}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Paper>
                                <Box pr={4} pt={2}>
                                    <Typography align="center" co={theme_color} variant="h6">
                                        Completed Jobs
                                    </Typography>
                                    <ResponsiveContainer width="100%" height={200}>
                                        {!this.props.stats ?
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <CircularProgress />
                                            </Box>
                                         :
                                            <BarChart data={this.props.stats} barCategoryGap={0}>
                                                <XAxis dataKey="x" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar
                                                    dataKey="y"
                                                    fill={theme_color}
                                                    background={{
                                                        fill: '#F8F8F8'
                                                    }}
                                                />
                                                <CartesianGrid
                                                    vertical={false}
                                                    strokeDasharray="3 3"
                                                />
                                            </BarChart>
                                        }
                                    </ResponsiveContainer>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={2}>
                          <Paper>
                              <Box p={2}>
                                  <Typography align="center" color={theme_color} variant="h6">
                                      Monthly Statements
                                  </Typography>
                                  <Box
                                      textAlign="right"
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      pt={4}
                                      height={152}
                                  >
                                  {this.props.statements && this.props.statements.length > 0 ?
                                      <div styles={{alignItems: 'center', justifyContent: 'center', margin: 15}}>
                                      <TextField
                                          name="statement"
                                          select
                                          fullWidth
                                          label="Select Statement"
                                          value={this.state.selectedStatement}
                                          onChange={e => {
                                              this.setState({selectedStatement: e.target.value});
                                          }}
                                      >
                                          {this.props.statements.map((s, i) => (
                                              <MenuItem key={i} value={s}>
                                                  {new Date(s.dateIssued).toLocaleDateString()}
                                              </MenuItem>
                                          ))}
                                      </TextField>
                                      <Button
                                        variant="outlined"
                                        style={{width: 300,
                                              marginTop: 40,
                                              backgroundColor: '#fff',
                                              margin: 10,
                                              width: 200,
                                              height: 50,
                                              color: theme_color,
                                              border: '2px solid ' + theme_color}}
                                          onClick={() => {
                                              this.props.enqueueSnackbar('Downloading Statement...');
                                              this.props.getDocumentsByURL([this.state.selectedStatement.url]).then((res) => {
                                                if(res){
                                                  this.props.enqueueSnackbar("Statement downloaded", {variant: 'success'});
                                                }else{
                                                  this.props.enqueueSnackbar("Could not download statement", {variant: 'error'});
                                                }
                                              })
                                            }
                                          }
                                      >
                                          Download
                                      </Button>
                                      </div> : null}
                                  </Box>
                              </Box>
                          </Paper>
                          </Grid>
                        <Grid item xs={12}>
                            <Cases />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Popover
                id={this.props.alerts_active ? this.props.alerts_active[0].alert_id: null}
                open={this.props.alerts_open}
                anchorEl={this.props.alert_anchor ? this.props.alert_anchor: null}
                onClose={() => this.props.closeModals()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box p={4}>
                    {this.props.alerts_active ? this.props.alerts_active.map((a, i) => (
                      <Box display="flex" alignItems="center">
                          <Box pr={2} style={{margin: 10}}>
                              <Typography>{i+1}) {a.message}</Typography>
                          </Box>
                      </Box>
                    )): null}
                </Box>
            </Popover>
            <UploadDialog />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    statements: state.statements,
    apiToken: state.apiToken,
    client: state.client,
    alerts: state.alerts,
    alerts_open: state.alerts_open,
    alerts_active: state.alerts_active,
    alert_anchor: state.alert_anchor,
    login_success: state.login_success,
    stats: state.stats
  }
}

export default withSnackbar(withRouter(connect(
  mapStateToProps,
  {login, logout, getCases, getClient, getStatements, jobsCompletedMetrics, getDocumentsByURL, getAlerts, closeModals}
)(Dashboard)))
