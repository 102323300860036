import { createMuiTheme } from '@material-ui/core/styles';
import {theme_color} from '../../tempfile'

export const theme = createMuiTheme({
    palette: {
      primary: {
          main: theme_color
      },
      secondary: {
          main: theme_color
      },
    },
    typography: {
        fontFamily: ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
            ','
        )
    }
});
