import {
	LOGIN_SUCCEEDED,
  LOGIN_FAILED,
	CLIENT_LOADED,
	LOGOUT,
  CASES_RECEIVED,
  ALERTS_RECEIVED,
  STATS_RECEIVED,
  DOCS_RECEIVED,
  STATEMENTS_RECEIVED,
  ADD_DOCS,
  OPEN_ALERT,
  OPEN_UPLOAD_DIALOG,
  CLOSE_MODALS
} from './types'

import React from 'react';

const INITIAL_STATE = {
	apiToken: null,
  client: null,
	login_success: false,
  cases: null,
  alerts: null,
  stats: null,
  docs: null,
  statements: null,

  alerts_open: false,
  alerts_active: null,
	alert_anchor: null,
  doc_upload_open: false,
  upload_meta: null
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case LOGIN_SUCCEEDED:
			console.log("login succeeded")
			return { ...state, apiToken: action.payload, login_success: true };
    case LOGIN_FAILED:
			console.log("login failed")
  		return { ...state, login_success: false };
		case CLIENT_LOADED:
			return { ...state, client: action.payload };
		case LOGOUT:
			return { ...state, apiToken: null };
    case CASES_RECEIVED:
			return { ...state, cases: action.payload };
    case ALERTS_RECEIVED:


			if(state.cases){
	      state.cases.forEach(c => {
	          action.payload.forEach(a => {
	              if (a.type === 'case' && a.refId === c.id) {
	                  c.alerts.push(a);
	              } else if (a.type === 'job') {
	                  c.jobs.forEach(j => {
	                      if (a.refId === j.id) {
	                          j.alerts.push(a);
	                      }
	                  });
	              }
	          });
	      });
				return { ...state, alerts: action.payload, cases: [...state.cases] };
			}

      return { ...state, alerts: action.payload };
    case STATS_RECEIVED:
			return { ...state, stats: action.payload };
    case DOCS_RECEIVED:
      return { ...state, docs: action.payload};
    case STATEMENTS_RECEIVED:
      return { ...state, statements: action.payload};
    case ADD_DOCS:
      return { ...state, docs_added: action.payload};
    case OPEN_ALERT:
			console.log('alert context: ', action.payload)
      return { ...state, alerts_open: true, alerts_active: action.payload.alerts, alert_anchor: action.payload.component};
    case OPEN_UPLOAD_DIALOG:
      return { ...state, doc_upload_open: true, upload_meta: action.payload};
    case CLOSE_MODALS:
      return { ...state, doc_upload_open: false, alerts_open: false};
    default:
			return { ...state };
  }
}
